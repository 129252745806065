import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { updateCheckoutData } from "../../redux/salesNewCreate/checkoutSlice";

const AddNoteModel = ({ show, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { checkoutData } = useSelector((state) => state.checkout);

  const [formData, setFormData] = useState({
    notes: "",
  });

  const [errors, setErrors] = useState({});
  useEffect(() => {
    setFormData((prevData) => ({ ...prevData, notes: checkoutData?.notes }));
  }, [checkoutData]);

  // Handle Input Change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Clear errors
  };

  // Validate Form
  const validateForm = () => {
    const newErrors = {};
    if (!formData.notes) newErrors.notes = "Please Add Note";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  // Handle product deletion
  // Submit Handler
  const handleSubmit = () => {
    if (validateForm()) {
      let newCheckoutData = {
        ...checkoutData,
        notes: formData.notes,
      };
      dispatch(updateCheckoutData(newCheckoutData));
      onClose(); // Close the offcanvas
    }
  };

  return (
    <Modal centered size="md" show={show} onHide={onClose}>
      <Modal.Header className="border-0 pb-0" closeButton>
        <Modal.Title>Note</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <Row className="gy-4 mb-4">
          <Col md={12}>
            <Form.Group>
              {/* <Form.Label>Category name*</Form.Label> */}
              {/* <p>Category name*</p> */}
              <Form.Control
                type="text"
                as="textarea"
                rows={3}
                name="notes"
                placeholder="Any notes against this invoice"
                value={formData?.notes}
                onChange={handleInputChange}
                isInvalid={!!errors.notes}
              />
              <Form.Control.Feedback type="invalid">
                {errors.notes}
              </Form.Control.Feedback>
            </Form.Group>
            <p className="text-start my-4">
              This will be saved in client details section
            </p>
          </Col>
        </Row>
        <div className="hstack gap-2 justify-content-center">
          {/* <Button
            size="sm"
            className="px-8"
            variant="outline-dark"
            onClick={handleDelete}
          >
            Yes
          </Button> */}
          <Button
            size="sm"
            className="px-8 w-full"
            variant="secondary"
            // onClick={onClose}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNoteModel;
