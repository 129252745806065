import { createAsyncThunk } from "@reduxjs/toolkit";
import { loadingEnd, loadingStart } from "../setLoading/setLoading";
import API from "../../apis/api";
import ToastMessage from "../../component/toast/ToastMessage";


// Thunks for API calls
export const fetchVendors = createAsyncThunk(
  'vendors/fetchAll',
  async ({search}, { rejectWithValue , dispatch}) => {
    dispatch(loadingStart());
    try {
      const backend = new API();
      const response = await backend.fetch(API.getVendor(search), {
        method: "GET",
      });
      dispatch(loadingEnd());
      return response?.data;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error fetching Vendors:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const addVendor = createAsyncThunk(
  'vendors/add',
  async (vendorData, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    const formData = new FormData();
    for (const key in vendorData) {
      formData.append(key, vendorData[key]);
    }
    try {
      const backend = new API();
      const response = await backend.fetch(API.addVendor(), {
        method: "POST",
        body: formData,
      });
      dispatch(loadingEnd());
      ToastMessage(response);
      return response.data;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error adding Vendor:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const editVendor = createAsyncThunk(
  'vendors/edit',
  async ({ id, updatedData }, {  rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    const formData = new FormData();
    for (const key in updatedData) {
      formData.append(key, updatedData[key]);
    }
    try {
      const backend = new API();
      const response = await backend.fetch(API.editVendor(id), {
        method: "POST",
        body: formData,
      });
      dispatch(loadingEnd());
      ToastMessage(response);
      return response.data;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error editing vendor:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const deleteVendor = createAsyncThunk(
  'vendors/delete',
  async (id, {  rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
   
    try {
      const backend = new API();
      const response = await backend.fetch(API.deleteVendor(id), {
        method: "POST",
      });
      dispatch(loadingEnd());
      ToastMessage(response);
      return response.data;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Deleting vendor:", error);
      return rejectWithValue(error.message);
    }
  }
);
// export const deleteVendor = createAsyncThunk(
//   'vendors/delete',
//   async (id, { rejectWithValue }) => {
//     try {
//       await API.delete(`/vendors/${id}`);
//       return id;
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );
