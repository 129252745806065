import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Offcanvas,
  Row,
  Stack,
} from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import { TbCirclePlus } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { getClient } from "../../redux/appointment";
import AddClientModel from "../Models/AddClientModel";
import { hasEditPermission } from "../../utils/validation";
import { formatPhoneNumberText } from "../../utils/commonFunctions";

function SelectClient({
  showOffcanvasSelectClient,
  setShowOffcanvasSelectClient,
  setSelectedClient,
}) {
  const dispatch = useDispatch();
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [selectedClientData, setSelectedClientData] = useState({});
  const [smShow, setSmShow] = useState(false);

  const handleRadioChange = (item) => {
    setSelectedClientData(item);
    setSelectedClientId(item?._id === selectedClientId ? null : item?._id);
  };
  const [clientSearchInput, setClientSearchInput] = useState("");
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setClientSearchInput(e.target.value);
    // dispatch(getClient(value));
  };
  useEffect(() => {
    let timeoutId;
  
    if (clientSearchInput.trim() === "") {
      // If input is empty, dispatch immediately to show all clients
      dispatch(getClient(""));
    } else {
      // Clear the previous timeout for debounce effect
      clearTimeout(timeoutId);
  
      // Set a new timeout for the debounce
      timeoutId = setTimeout(() => {
        dispatch(getClient(clientSearchInput));
      }, 300);
    }
  
    // Cleanup the timeout on component unmount or input change
    return () => {
      clearTimeout(timeoutId);
    };
  }, [clientSearchInput, dispatch]);

  const allClient = useSelector((state) => state?.appointment?.allClient);

  function isObjectEmpty(data) {
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
  const [sortedClientList, setSortedClientList] = useState([]);
  useEffect(() => {
    if (allClient && isObjectEmpty(allClient)) {
      dispatch(getClient());
    } else {
      // Assuming allClient is the array you're mapping over
      const sortedClients = [...allClient?.data];

      // Separate names into alphabetic and non-alphabetic groups
      const alphabeticNames = sortedClients.filter((item) =>
        /^[A-Za-z]/.test(item.name)
      );
      const nonAlphabeticNames = sortedClients.filter(
        (item) => !/^[A-Za-z]/.test(item.name)
      );

      // Sort alphabetic names alphabetically
      alphabeticNames.sort((a, b) => a.name.localeCompare(b.name));

      // Combine alphabetic and non-alphabetic names
      const combinedSortedClients = [...alphabeticNames, ...nonAlphabeticNames];
      setSortedClientList(combinedSortedClients);
    }
  }, [allClient, dispatch]); // Add allClient as a dependency

  const handleSaveChange = () => {
    setSelectedClient(selectedClientData); // Update setSelectedClient here
    setShowOffcanvasSelectClient(false); // Close the Offcanvas after saving changes
  };
  let currentLetter = ""; // Variable to track the current letter being processed
  
  const modulesPermissions = useSelector((state) => state?.authSession?.modulesPermissions);
  return (
    <div>
      <Offcanvas
        show={showOffcanvasSelectClient}
        onHide={() => setShowOffcanvasSelectClient(false)}
        placement={"end"}
        className="margin-top-72px"
        scroll={false}
      >
        <Offcanvas.Header className="py-4" closeButton>
          <h5 className="text-md">Select client</h5>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form className="h-full vstack ">
            <Row className="mb-6">
              <Col sm={9}>
                <InputGroup className="form-group-1 border rounded-pill ">
                  <InputGroup.Text className="border-0  bg-transparent pe-1 ps-3">
                    <BiSearch className="text-lg" />
                  </InputGroup.Text>
                  <FormControl
                    className="border-0 ps-1 rounded-pill "
                    type="text"
                    name="search"
                    placeholder="Search by name, number"
                    onChange={handleSearchChange}
                    autoComplete="off"
                  />
                </InputGroup>
              </Col>
              <Col sm={3}>
                <Button
                  variant=""
                  className="text-secondary text-sm px-0 border-0 shadow-none d-flex align-items-center "
                  onClick={() => setSmShow(true)}
                  disabled={!hasEditPermission(modulesPermissions?.client?.is_create)}
                >
                  <TbCirclePlus className=" me-2 text-md" />
                  Client
                </Button>
              </Col>
            </Row>
            <p className="text-sm">All clients ({allClient?.data?.length})</p>
            <Row className="row-cols-sm-1 gy-4 pb-24">
              {sortedClientList.map((item, index) => {
                // Extract the first letter of the name
                const firstLetter = item.name.charAt(0).toUpperCase();

                // Check if it's a new letter
                const isNewLetter = firstLetter !== currentLetter;

                // Update the current letter
                currentLetter = firstLetter;
                return (
                  <React.Fragment key={index}>
                    {/* Render the heading if it's a new letter */}
                    {isNewLetter && (
                      <>
                        <p className="text-sm text-dark font-bold mb-1">
                          {currentLetter}
                        </p>
                        <hr className="mt-0" />
                      </>
                    )}
                    <Stack
                      key={index}
                      direction="horizontal"
                      className="justify-content-between mt-0 mb-1"
                    >
                      <div className="">
                        <p className="text-sm mb-1 text-dark font-bold">
                          {item?.name}
                        </p>
                        <p className="text-xs font-semibold">{formatPhoneNumberText(item?.phone)}</p>
                      </div>
                      <Form.Check
                        id={`clientRadio_${item._id}`}
                        label="Female"
                        variant="secondary"
                      >
                        <Form.Check.Input
                          type="radio"
                          name="clientRadioGroup"
                          className="secondary-radio"
                          checked={selectedClientId === item._id}
                          onChange={() => handleRadioChange(item)}
                        />
                      </Form.Check>
                    </Stack>
                  </React.Fragment>
                );
              })}
            </Row>
            <div className="mt-auto position-absolute bottom-0 bg-white p-6 w-full start-0 overlap-20">
              <Button
                variant="secondary"
                className="w-full"
                onClick={handleSaveChange}
              >
                Save changes
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      {smShow && <AddClientModel setSmShow={setSmShow} smShow={smShow} />}
    </div>
  );
}

export default SelectClient;
