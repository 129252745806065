import { api_endpoints, api_host } from "./constants";

export default class API {
  constructor() {}

  static inventoryProductListGet(page = 1, per_page = 5000, search) {
    let url =
      api_host +
      api_endpoints.inventoryProductListGet +
      `?page=${page}` +
      `&per_page=${per_page}`;
    if (search) {
      url += `&search=${search}`;
    }
    return url;
  }
  static inventoryAllProductListGet(page = 1, per_page = 5000, search) {
    let url =
      api_host +
      api_endpoints.inventoryAllProductListGet +
      `?page=${page}` +
      `&per_page=${per_page}`;
    if (search) {
      url += `&search=${search}`;
    }
    return url;
  }
  static inventoryEditeProduct(id) {
    return api_host + api_endpoints.inventoryEditeProduct + id;
  }
  static inventoryStockAdd(id) {
    return api_host + api_endpoints.inventoryStockAdd + id;
  }
  static inventoryProductAdd() {
    return api_host + api_endpoints.inventoryProductAdd;
  }
  static productImport() {
    return api_host + api_endpoints.productImport;
  }
  static authSessionCreate() {
    return api_host + api_endpoints.authSessionCreate;
  }
  static loginUser() {
    return api_host + api_endpoints.loginUser;
  }
  static verifyOtp() {
    return api_host + api_endpoints.verifyOtp;
  }
  static resendOtp() {
    return api_host + api_endpoints.resendOtp;
  }
  static packagesList() {
    return api_host + api_endpoints.packagesList + "?type=list";
  }
  static packagesEdite(id) {
    return api_host + api_endpoints.packageEdit + id;
  }
  static packagesDelete(id) {
    return api_host + api_endpoints.packageDelete + id;
  }
  static packageAdd() {
    return api_host + api_endpoints.packageAdd;
  }
  static getStaff() {
    return api_host + api_endpoints.getStaff;
  }
  static getClient(params) {
    let url = api_host + api_endpoints.getClient;
    if (params) {
      url += `?search=${params}`;
    }
    return url;
  }
  static membersAdd() {
    return api_host + api_endpoints.membersAdd;
  }
  static membersList() {
    return api_host + api_endpoints.membersList;
  }
  static membersDelete(id) {
    return api_host + api_endpoints.membersDelete + id;
  }
  static membersEdit(id) {
    return api_host + api_endpoints.membersEdit + id;
  }
  static contactUsApi() {
    return api_host + api_endpoints.contactUsApi;
  }
  static walletList(id) {
    return api_host + api_endpoints.walletList + id;
  }
  static getServices(params) {
    let url = api_host + api_endpoints.getServices;
    if (params) {
      url += `?search=${params}`;
    }
    return url;
  }
  static bookAppoiment() {
    return api_host + api_endpoints.bookAppoimentAdd;
  }
  static getBookAppoiment(status, staff, search, date) {
    return (
      api_host +
      api_endpoints.getBookAppoiment +
      "?" +
      `${staff ? "staff=" + staff + "&" : ""}` +
      `${status ? "status=" + status + "&" : ""}` +
      `${search ? "search=" + search + "&" : ""}` +
      `${date ? "date=" + date + "&" : ""}`
    );
  }
  static allAppointmentDetails() {
    return api_host + api_endpoints.allAppointmentDetails;
  }
  static bookAppoimentEdite(id) {
    return api_host + api_endpoints.bookAppoimentEdite + id;
  }
  static staffAdd() {
    return api_host + api_endpoints.staffAdd;
  }
  static staffList() {
    return (
      api_host + api_endpoints.staffList + "?" + `${"per_page=" + 5000 + "&"}`
    );
  }
  static staffEdit(id) {
    return api_host + api_endpoints.staffEdit + id;
  }
  static clientList(search, tag) {
    return (
      api_host +
      // api_endpoints.clientList +
      api_endpoints.getClient +
      "?" +
      `${"per_page=" + 5000 + "&"}` +
      `${search ? "search=" + search + "&" : ""}` +
      `${tag && tag !== "All" ? "clientTag=" + tag + "&" : ""}`
    );
  }
  static clientAdd() {
    return api_host + api_endpoints.clientAdd;
  }
  static enquiryAdd() {
    return api_host + api_endpoints.enquiryAdd;
  }
  static enquiryList(enquiryType, representative, search, enquiryStatus, date) {
    return (
      api_host +
      api_endpoints.enquiryList +
      "?" +
      `${enquiryType ? "enquiry_type=" + enquiryType + "&" : ""}` +
      `${
        enquiryStatus && enquiryStatus !== "All"
          ? "enquiry_status=" + enquiryStatus + "&"
          : ""
      }` +
      `${
        representative ? "enquiry_representative=" + representative + "&" : ""
      }` +
      `${search ? "search=" + search + "&" : ""}` +
      `${date ? "date=" + date + "&" : ""}`
    );
  }

  static staffDelete(id) {
    return api_host + api_endpoints.staffDelete + id;
  }
  static enquiryEdite(id) {
    return api_host + api_endpoints.enquiryEdite + id;
  }
  static getStaffAvailabilty(id) {
    return api_host + api_endpoints.getStaffAvailabilty + id;
  }
  static clientDetailesUpdate(id) {
    return api_host + api_endpoints.clientDetailesUpdate + id;
  }
  static getAllAppointments(id, staff, status, search) {
    return (
      api_host +
      api_endpoints.getAllAppointments +
      id +
      "?" +
      `${staff ? "staff=" + staff + "&" : ""}` +
      `${status ? "status=" + status + "&" : ""}` +
      `${search ? "search=" + search + "&" : ""}`
    );
  }
  static getAllInvoceHistory(id, staff, status, search) {
    return (
      api_host +
      api_endpoints.getAllInvoceHistory +
      id +
      "?" +
      `${staff ? "staff=" + staff + "&" : ""}` +
      `${status ? "status=" + status + "&" : ""}` +
      `${search ? "search=" + search + "&" : ""}`
    );
  }
  static staffAvailabiltyCalendar(id) {
    return (
      api_host + api_endpoints.staffAvailabiltyCalendar + `?staff_id=${id}`
    );
  }
  static staffAvailabiltyCalendarAdd() {
    return api_host + api_endpoints.staffAvailabiltyCalendarAdd;
  }
  static recordSaleAdd() {
    return api_host + api_endpoints.recordSaleAdd;
  }
  static recordSaleList(date, search, number, page, per_page) {
    return (
      api_host +
      api_endpoints.recordSaleList +
      "?" +
      `${date ? "date=" + date + "&" : ""}` +
      `${search ? "search=" + search + "&" : ""}` +
      `${number ? "number=" + number + "&" : ""}` +
      `${page ? "page=" + page + "&" : ""}` +
      `${per_page ? "per_page=" + per_page + "&" : ""}`
    );
  }
  static listRecordInventory(id) {
    return api_host + api_endpoints.listRecordInventory + id;
  }
  static recordInventory(id) {
    return api_host + api_endpoints.recordInventory + id;
  }
  static deleteCheckout(id) {
    return api_host + api_endpoints.deleteCheckout + id;
  }
  static getCheckoutDetails(id) {
    return api_host + api_endpoints.getCheckoutDetails + id;
  }
  static updateCheckoutDetails(id) {
    return api_host + api_endpoints.updateCheckoutDetails + id;
  }
  static staffAvailabiltyHours() {
    return api_host + api_endpoints.staffAvailabiltyHours;
  }
  // static InsightLits(id) {
  //   return api_host + api_endpoints.InsightLits + id;
  // }
  static InsightLits({ tag, start_date, end_date }) {
    return (
      api_host +
      api_endpoints.InsightLits +
      tag +
      "?" +
      `${start_date ? "start_date=" + start_date + "&" : ""}` +
      `${end_date ? "end_date=" + end_date + "&" : ""}`
    );
  }
  static dashboradInsightsList() {
    return api_host + api_endpoints.dashboradInsightsList;
  }
  static generateRandomBarcode() {
    return api_host + api_endpoints.generateRandomBarcode;
  }
  static miniWebsite(id) {
    return api_host + api_endpoints.miniWebsite + id;
  }
  static miniAppointmentBook() {
    return api_host + api_endpoints.miniAppointmentBook;
  }
  static getSubServicesSearch(params) {
    let url = api_host + api_endpoints.getSubServicesSearch;
    if (params) {
      url += `?search=${params}`;
    }
    return url;
  }
  static getCoupon(id) {
    return api_host + api_endpoints.getCoupon + `?coupon_code=${id}`;
  }
  static getProfileStatus() {
    return api_host + api_endpoints.getProfileStatus;
  }
  static getLocationFromPinCode(id) {
    return api_host + api_endpoints.getLocationFromPinCode + id;
  }
  static createCampaignAutomationReminder() {
    return api_host + api_endpoints.createCampaignAutomationReminder;
  }
  static allServicesTaken(id, search) {
    return (
      api_host +
      api_endpoints.allServicesTaken +
      id +
      "?" +
      `${search ? "search=" + search + "&" : ""}`
    );
  }
  static getTemplates() {
    return api_host + api_endpoints.getTemplates;
  }
  static getCampaignTemplate(params) {
    let url = api_host + api_endpoints.getCampaignTemplate;
    if (params) {
      url += `?template_status=${params}`;
    }
    return url;
  }
  static getTemplateDetails() {
    return api_host + api_endpoints.getTemplateDetails;
  }
  static addReminder(id) {
    return api_host + api_endpoints.addReminder + id;
  }
  static campaignAutomationReminder() {
    return api_host + api_endpoints.campaignAutomationReminder;
  }
  static updateAppointmentStatus(id) {
    return api_host + api_endpoints.updateAppointmentStatus + id;
  }
  static cancelAppointment() {
    return api_host + api_endpoints.cancelAppointment;
  }
  static initialCampaignData() {
    return api_host + api_endpoints.initialCampaignData;
  }
  static getTemplateImageList() {
    return api_host + api_endpoints.getTemplateImageList;
  }
  static addBalanceApi(id) {
    return api_host + api_endpoints.addBalanceApi + id;
  }
  static templateImageList() {
    return api_host + api_endpoints.templateImageList;
  }
  static addCampaignTemplate() {
    return api_host + api_endpoints.addCampaignTemplate;
  }
  static getAllCustomers() {
    return api_host + api_endpoints.getAllCustomers;
  }
  static getAdvanceFilters(field, value) {
    return (
      api_host +
      api_endpoints.getAdvanceFilters +
      "?" +
      `${field ? field + "=" + value + "&" : ""}`
    );
  }
  static sendCampaign() {
    return api_host + api_endpoints.sendCampaign;
  }
  static sendCampaignMessage() {
    return api_host + api_endpoints.sendCampaignMessage;
  }
  static reviewCampaign() {
    return api_host + api_endpoints.reviewCampaign;
  }
  static getAllExpenses(
    search,
    start_date,
    end_date,
    payment_mode,
    expense_type
  ) {
    return (
      api_host +
      api_endpoints.getAllExpenses +
      "?" +
      `${search ? "search=" + search + "&" : ""}` +
      `${start_date ? "start_date=" + start_date + "&" : ""}` +
      `${end_date ? "end_date=" + end_date + "&" : ""}` +
      `${payment_mode ? "payment_mode=" + payment_mode + "&" : ""}` +
      `${expense_type ? "expense_type=" + expense_type + "&" : ""}`
    );
  }

  static addExpense() {
    return api_host + api_endpoints.addExpense;
  }

  static editExpense(id) {
    return api_host + api_endpoints.editExpense + id;
  }

  static deleteExpense(id) {
    return api_host + api_endpoints.deleteExpense + id;
  }

  static getExpense(id) {
    return api_host + api_endpoints.getExpense + id;
  }

  static deleteReminder(id) {
    return api_host + api_endpoints.deleteReminder + id;
  }
  static getAllExpenseCategories() {
    return api_host + api_endpoints.getAllExpenseCategories;
  }
  static getAllReports({ reportType, subReportType, filterParams }) {
    return `${api_host}${reportType}/${subReportType}?device=web&${filterParams}`;
  }
  static getAllFiltersForReport() {
    return api_host + api_endpoints.getAllFiltersForReport + "?device=web";
  }
  static addBranches() {
    return api_host + api_endpoints.addBranches;
  }
  static getAllBranches() {
    return api_host + api_endpoints.getAllBranches;
  }
  static editBranches(id) {
    return api_host + api_endpoints.editBranches + id;
  }
  static getBranch(id) {
    return api_host + api_endpoints.getBranch + id;
  }
  static switchBranch(id) {
    return api_host + api_endpoints.switchBranch + id;
  }
  static getAllStaffPermission(id) {
    return api_host + api_endpoints.getAllStaffPermission + id;
  }
  static getStaffPermission(id) {
    return api_host + api_endpoints.getStaffPermission + id;
  }
  static assignWebStaffPermission(id) {
    return api_host + api_endpoints.assignWebStaffPermission + id;
  }
  static changeStaffPassword(id) {
    return api_host + api_endpoints.changeStaffPassword + id;
  }
  static removeStaffOperator(id) {
    return api_host + api_endpoints.removeStaffOperator + id;
  }
  static staffLogin() {
    return api_host + api_endpoints.staff_login;
  }

  static getVendor(search) {
    return (
      api_host +
      api_endpoints.getVendor +
      "?" +
      `${search ? "search=" + search + "&" : ""}`
    );
  }

  static addVendor() {
    return api_host + api_endpoints.addVendor;
  }

  static editVendor(id) {
    return api_host + api_endpoints.editVendor + id;
  }

  static deleteVendor(id) {
    return api_host + api_endpoints.deleteVendor + id;
  }

  // New Inventory

  static getInventoryProductList({ type, search, category, page }) {
    return (
      api_host +
      api_endpoints.getInventoryProductList +
      "?" +
      `${type ? "product_for=" + type + "&" : ""}` +
      `${search ? "search=" + search + "&" : ""}` +
      `${page ? "page=" + page + "&" : ""}`
    );
  }

  static addInventoryProduct() {
    return api_host + api_endpoints.addInventoryProduct;
  }
  static editInventoryProduct(id) {
    return api_host + api_endpoints.editInventoryProduct + id;
  }
  static delete_product(id) {
    return api_host + api_endpoints.delete_product + id;
  }
  static addStock(id) {
    return api_host + api_endpoints.addStock + id;
  }
  static reduceStock(id) {
    return api_host + api_endpoints.reduceStock + id;
  }
  static get_product_categories() {
    return api_host + api_endpoints.get_product_categories;
  }
  static add_product_categories() {
    return api_host + api_endpoints.add_product_categories;
  }
  static getMasterProduct({ barcode }) {
    return (
      api_host +
      api_endpoints.getMasterProduct +
      "?" +
      `${barcode ? "sku=" + barcode + "&" : ""}`
    );
  }

  // New POS

  static getPosLists() {
    return (
      api_host +
      api_endpoints.getPosLists +
      "?" +
      `${ "device=" + "" + "&"}` // device = web
    );
  }

  static getRecordLists({ perPage, page, search }) {
    return (
      api_host +
      api_endpoints.getRecordLists +
      "?" +
      `${perPage ? "perPage=" + perPage + "&" : ""}` +
      `${page ? "page=" + page + "&" : ""}` +
      `${search ? "search=" + search + "&" : ""}`
      // `${ "search=" + search + ""}`
    );
  }

  static processWebCheckout() {
    return api_host + api_endpoints.processWebCheckout;
  }
  
  static getProcessWebCheckout(id) {
    return api_host + api_endpoints.getProcessWebCheckout + id;
  }
  static editProcessWebCheckout(id) {
    return api_host + api_endpoints.editProcessWebCheckout + id;
  }

  static getSellerLoyaltyDetails() {
    return api_host + api_endpoints.getSellerLoyaltyDetails;
  }



  _checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else if (response.status === 401) {
      localStorage.clear();
      window.location.href = "/login"; // Redirect to the login page
      return response;
    } else if (response.status === 400) {
      return response;
    } else {
      throw new Error(response);
    }
  }

  async fetch(url, options) {
    const headers = {
      Accept: "multipart/form-data",
      "access-control-allow-origin": "*",
      Authorization: `Bearer  ${localStorage.getItem("token")}`, // Replace with your actual token
      "X-Logged-branch-ID": localStorage.getItem("branchId") || "undefined",
      // mode : "no-cors"
    };
    // Check if the URL already has a query string
    const branchId = localStorage.getItem("branchId") || "undefined";
    const separator = url.includes("?") ? "&" : "?";
    const newUrl = `${url}${separator}branchId=${branchId}`;
    const response = await fetch(newUrl, {
      headers,
      ...options,
    });
    // const response = await fetch(url, {
    //   headers,
    //   ...options,
    // });
    const response_1 = await this._checkStatus(response);
    return response_1.json();
  }
}
