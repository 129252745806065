import React, { useState, useEffect, Fragment } from "react";
import CommonLayout from "../../component/commonLayout";
import {
  Card,
  CardBody,
  Tabs,
  Tab,
  Row,
  Col,
  Image,
  Button,
  InputGroup,
  FormControl,
  Dropdown,
  Stack,
  Table,
  Form,
  FormGroup,
  Alert,
  Modal,
  Nav,
} from "react-bootstrap";
import { BiPlus, BiSave, BiSearch } from "react-icons/bi";
import { RiBarcodeFill, RiDeleteBin5Line } from "react-icons/ri";
import { AiOutlineClear } from "react-icons/ai";
import { PiNote } from "react-icons/pi";
import { HiOutlineMinusSmall, HiOutlinePlusSmall } from "react-icons/hi2";
import Select from "react-select";

import { Cash, GoldCoin, TwoCoins } from "../../assets/icons";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate, useParams } from "react-router-dom";

import DateTime from "react-datetime";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  convertTimeToUTC,
  convertUTCToUserTimeZone,
  getClassNameForClientTag,
  getCurrencySymbolIcon,
  getCurrencySymbolText,
  getCurrencySymbolTextForDropdown,
  getCurrencySymbolTextforTd,
  getTaxOptionsAndPercentage,
  getTaxOptionsAndRate,
  isObjectEmpty,
} from "../../utils/commonFunctions";

import PageLoader from "../../component/commonLayout/PageLoader";
import PermissionGuard from "../../component/commonLayout/PermissionGuard";
import {
  addCheckout,
  editCheckout,
  fetchCheckoutDetails,
  fetchCheckoutItemLists,
} from "../../redux/salesNewCreate";
import { FaAngleDown, FaTimes } from "react-icons/fa";
import { IoClose, IoCloseSharp } from "react-icons/io5";
import {
  clearCheckoutData,
  updateCheckoutData,
  updateSalesData,
} from "../../redux/salesNewCreate/checkoutSlice";
import SelectClient from "../../component/OffCanvases/SelectClient";
import AddNoteModel from "./AddNoteModel";
import { toast } from "react-toastify";
import API from "../../apis/api";
import { loadingEnd, loadingStart } from "../../redux/setLoading/setLoading";

const AmountInputForm = ({
  selectedClient,
  paymentEntries,
  setPaymentEntries,
  payableAmount,
  error,
  setError,
}) => {
  const [entries, setEntries] = useState([...paymentEntries]);
  useEffect(() => {
    setEntries(paymentEntries);
  }, [paymentEntries]);

  const [totalAmount, setTotalAmount] = useState(payableAmount);
  const [remainingAmountState, setRemainingAmountState] =
    useState(payableAmount);

  const calculateRemainingAmount = () => {
    const remainingAmount =
      totalAmount -
      entries.reduce((sum, entry) => sum + (parseFloat(entry.price) || 0), 0);
    setRemainingAmountState(remainingAmount);
    return remainingAmount;
  };
  useEffect(() => {
    setTotalAmount(parseFloat(payableAmount) || 0);
    calculateRemainingAmount();
  }, [payableAmount]);
  useEffect(() => {
    calculateRemainingAmount();
    if (entries.length < 1) {
      setEntries([
        ...entries,
        { id: Date.now(), price: "", paymentMode: "Cash" },
      ]);
    }
  }, [entries, totalAmount]);
  useEffect(() => {
    calculateRemainingAmount();
  }, []);
  useEffect(() => {
    setPaymentEntries(entries);
    setError({ ...error, ["remainingAmount"]: null });
  }, [entries]);

  // const handleInputChange = (index, field, value) => {
  //   const newEntries = [...entries];
  //   console.log("selectedClient", selectedClient?.reward_points);
  //   console.log("entry", index, field, value);

  //   if (
  //     field === "amount" &&
  //     newEntries[index].paymentMode === "RP (Reward Points)"
  //   ) {
  //     const isValidRewardPoints =
  //       selectedClient?.reward_points >= parseFloat(value);

  //     if (isValidRewardPoints || value === "") {
  //       newEntries[index] = {
  //         ...newEntries[index],
  //         [field]: parseFloat(value) || 0,
  //       };
  //     } else {
  //       // Show error for invalid reward points
  //       // You may want to handle the error appropriately, e.g., set an error state
  //       console.error("Invalid reward points");
  //       return;
  //     }
  //   } else {
  //     newEntries[index] = { ...newEntries[index], [field]: value };
  //   }

  //   setEntries(newEntries);
  // };

  const handleInputChange = (index, field, value) => {
    const newEntries = [...entries];
    // console.log("XXXXXX:", selectedClient?.reward_points, parseFloat(value));
    if (field === "price") {
      if (newEntries[index].paymentMode === "RP (Reward Points)") {
        if (value > selectedClient?.reward_points) {
          setError({
            ...error,
            ["rewardPoint"]: "value exceeds available Rewards.",
          });
        } else {
          setError({ ...error, ["rewardPoint"]: null });
        }

        newEntries[index] = {
          ...newEntries[index],
          [field]: parseFloat(value) || 0,
        };
      } else if (newEntries[index].paymentMode === "Balance") {
        if (value > selectedClient?.balance) {
          setError({
            ...error,
            ["balance"]: "value exceeds available Balance.",
          });
        } else {
          setError({ ...error, ["balance"]: null });
        }

        newEntries[index] = {
          ...newEntries[index],
          [field]: parseFloat(value) || 0,
        };
      } else if (newEntries[index].paymentMode === "Membership balance") {
        if (value > selectedClient?.service_balance) {
          setError({
            ...error,
            ["membershipBalance"]:
              "value exceeds available Membership Balance.",
          });
        } else {
          setError({ ...error, ["membershipBalance"]: null });
        }

        newEntries[index] = {
          ...newEntries[index],
          [field]: parseFloat(value) || 0,
        };
      } else {
        newEntries[index] = {
          ...newEntries[index],
          [field]: parseFloat(value) || 0,
        };
      }
    } else if (field === "paymentMode" && value === "RP (Reward Points)") {
      newEntries[index] = {
        ...newEntries[index],
        [field]: value,
        amount: selectedClient?.reward_points,
      };
    } else {
      newEntries[index] = { ...newEntries[index], [field]: value };
    }
    setEntries(newEntries);
  };

  const handleInputBlur = (index) => {
    return;
    const remainingAmount = calculateRemainingAmount();

    if (
      remainingAmount > 0 &&
      index === entries.length - 1 &&
      entries[index].amount !== 0
    ) {
      setEntries([...entries, { amount: 0, paymentMode: "Cash", notes: "" }]);
    } else if (entries.length !== 1) {
      const filteredEntries = entries.filter((entry, i) => entry.amount !== 0);
      setEntries(filteredEntries);
    }
  }; // removes extra input with 0 value in it
  const calculateTotalAmount = () => {
    setTotalAmount(entries.reduce((sum, amount) => sum + amount.value, 0));
  };

  const handleDeletePaymentMode = (index) => {
    setEntries((prevEntries) => {
      if (prevEntries.length === 1) {
        return prevEntries; // Do not delete if only one entry is left
      }
      return prevEntries.filter((entry, entryIndex) => entryIndex !== index);
    });
  };

  const handleAddPaymentMode = () => {
    setEntries((prevEntries) => [
      ...prevEntries,
      { id: Date.now(), price: "", paymentMode: "Cash" },
    ]);
  };
  return (
    <div style={{ maxWidth: "320px" }}>
      {entries?.map((entry, index) => (
        <Fragment key={`paymentEntry-${index}`}>
          <Row className="mb-1 gy-1 gx-2">
            <Col md={5}>
              <FormControl
                className="form-control-sm text-xs py-1"
                type="text"
                size="sm"
                name="price"
                placeholder="0"
                value={entry.price}
                onChange={(e) =>
                  handleInputChange(index, "price", e.target.value)
                }
                onBlur={(e) => {
                  handleInputBlur(index);
                }}
                disabled={
                  entry.paymentMode === "RP (Reward Points)" ||
                  entry.paymentMode === "Balance" ||
                  entry.paymentMode === "Membership balance"
                }
              />
            </Col>

            <Col md={5}>
              <Form.Select
                className="form-select-sm text-xs py-2"
                size="sm"
                name="paymentMode"
                value={entry.paymentMode}
                onChange={(e) =>
                  handleInputChange(index, "paymentMode", e.target.value)
                }
                disabled={
                  entry.paymentMode === "RP (Reward Points)" ||
                  entry.paymentMode === "Balance" ||
                  entry.paymentMode === "Membership balance"
                }
              >
                <option
                  disabled={entries.some(
                    (item) => item.paymentMode === "Cash" && item !== entry
                  )}
                >
                  Cash
                </option>
                <option
                  disabled={entries.some(
                    (item) => item.paymentMode === "UPI" && item !== entry
                  )}
                >
                  UPI
                </option>
                <option
                  disabled={entries.some(
                    (item) => item.paymentMode === "Card" && item !== entry
                  )}
                >
                  Card
                </option>
                <option
                  disabled={
                    entries.some(
                      (item) =>
                        item.paymentMode === "RP (Reward Points)" &&
                        item !== entry
                    ) ||
                    selectedClient?.reward_points == null ||
                    selectedClient?.reward_points === 0
                  }
                  // disabled
                >
                  RP (Reward Points)
                </option>
                <option
                  disabled={
                    entries.some(
                      (item) => item.paymentMode === "Balance" && item !== entry
                    ) ||
                    selectedClient?.balance == null ||
                    selectedClient?.balance === 0
                  }
                  // disabled
                >
                  Balance
                </option>
                <option
                  disabled={
                    entries.some(
                      (item) =>
                        item.paymentMode === "Membership balance" &&
                        item !== entry
                    ) ||
                    selectedClient?.service_balance == null ||
                    selectedClient?.service_balance === 0
                  }
                  // disabled
                >
                  Membership balance
                </option>
              </Form.Select>
            </Col>

            <Col md={2} className="d-flex align-items-center">
              <Button
                variant="secondary"
                size="sm"
                className=" p-2 d-flex align-items-center justify-content-center outline-none"
                onClick={() => handleDeletePaymentMode(index)}
                // disabled={entries?.length === 1}
                disabled={
                  entry.paymentMode === "RP (Reward Points)" ||
                  entry.paymentMode === "Balance" ||
                  entry.paymentMode === "Membership balance" ||
                  entries?.length === 1
                }
              >
                <RiDeleteBin5Line className="text-sm" />
              </Button>
            </Col>

            {entry?.paymentMode === "UPI" && (
              <Col md={12}>
                <FormControl
                  className="form-control-sm text-xs py-0"
                  type="text"
                  name="notes"
                  placeholder="Enter transaction ID/ notes here"
                  value={entry.notes}
                  onChange={(e) =>
                    handleInputChange(index, "upiId", e.target.value)
                  }
                />
              </Col>
            )}
          </Row>

          {entry?.paymentMode === "RP (Reward Points)" && (
            <>
              <p className="text-danger">{error?.rewardPoint}</p>
              <Row className="align-items-center mb-3">
                <Col md={8} className="d-flex align-items-center">
                  <Image src={TwoCoins} className="me-2" width="24" />
                  <span className="text-sm">Available Reward points</span>
                </Col>
                <Col md={4} className="text-end">
                  <p className="text-sm text-dark font-bold">
                    {selectedClient?.reward_points || 0} points
                  </p>
                </Col>
              </Row>
            </>
          )}

          {entry?.paymentMode === "Balance" && (
            <>
              <p className="text-danger">{error?.balance}</p>
              <Row className="align-items-center mb-3">
                <Col md={8} className="d-flex align-items-center">
                  <Image src={Cash} className="me-2" width="24" />
                  <span className="text-sm">Available balance</span>
                </Col>
                <Col md={4} className="text-end">
                  <p className="text-sm text-dark font-bold">
                    {getCurrencySymbolTextforTd(selectedClient?.balance || 0)}
                  </p>
                </Col>
              </Row>
            </>
          )}

          {entry?.paymentMode === "Membership balance" && (
            <>
              <p className="text-danger">{error?.membershipBalance}</p>
              <Row className="align-items-center mb-3">
                <Col md={8} className="d-flex align-items-center">
                  <Image src={GoldCoin} className="me-2" width="24" />
                  <span className="text-sm">Available Membership balance</span>
                </Col>
                <Col md={4} className="text-end">
                  <p className="text-sm text-dark font-bold">
                    {selectedClient?.service_balance || 0}
                  </p>
                </Col>
              </Row>
            </>
          )}
        </Fragment>
      ))}
      <Row>
        <Col md={12}>
          <Button
            variant="transparent"
            size="sm"
            className="w-full btn-outline-primary border-dashed rounded-1 mb-4 text-xs py-1"
            style={{ border: "1px dashed" }}
            onClick={handleAddPaymentMode}
          >
            <BiPlus className="text-md me-2" />
            Add Payment Mode
          </Button>
        </Col>
      </Row>

      <p className="text-danger">{error?.paymentEntries}</p>

      {/* <Row className="align-items-center mb-3">
        <Col md={6}>
          <p className="text-sm">Amount due</p>
        </Col>
        <Col md={6} className="text-end">
          <p className="text-sm text-dark font-bold">
            {getCurrencySymbolTextforTd(remainingAmountState.toFixed(2))}
          </p>
        </Col>
        <p className="text-danger">{error?.remainingAmount}</p>
      </Row> */}
    </div>
  );
};

const EditCheckout = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    checkoutItems,
    mainCategories,
    categories,
    salesData,
    checkoutData,
    loading,
    checkoutDetailes,
  } = useSelector((state) => state.checkout);

  useEffect(() => {
    if (checkoutItems && isObjectEmpty(checkoutItems)) {
      dispatch(fetchCheckoutItemLists());
    }
  }, [checkoutItems, dispatch]);

  const [first, setfirst] = useState(1);
  useEffect(() => {
    setfirst(first + 1);
  }, [checkoutItems]);

  const [activeTab, setActiveTab] = useState("Services");
  // const [activeTab, setActiveTab] = useState(mainCategories?.[0]?.value || "");
  const taxOptionsLables = getTaxOptionsAndPercentage();
  const [paymentEntries, setPaymentEntries] = useState([
    { id: Date.now(), price: "0", paymentMode: "Cash" },
    // {id: Date.now(), price: calculatePrice().finalPrice.toString(), paymentMode: 'Cash'} ,
  ]);
  const [error, setError] = useState({});

  const [showOffcanvasSelectClient, setShowOffcanvasSelectClient] =
    useState(false);
  const [showAddNoteModel, setShowAddNoteModel] = useState(false);

  const flattenItemsArray = () => {
    // Flatten the sales data for easy mapping in the table
    const itemsWithQuantity = salesData
      .flatMap((category) =>
        Object.entries(category.salesListing).map(([itemId, item]) => ({
          categoryKey: category.category,
          itemId,
          ...item,
        }))
      )
      .filter((item) => item.quantity > 0); // Only include items with quantity > 0

    return itemsWithQuantity;
  };

  // Calculate the total loyalty points
  const countLoyaltyPoints = () => {
    const items = flattenItemsArray();
    const totalLoyaltyPoints = items.reduce((total, item) => {
      return total + (parseInt(item.loyaltyPoint) || 0); // Convert loyaltyPoint to a number and add it
    }, 0);

    return totalLoyaltyPoints;
  };
  const calculateSubTotal = () => {
    const items = flattenItemsArray();
    const totalLoyaltyPoints = items.reduce((total, item) => {
      return total + (parseInt(item.itemFinalPrice) || 0); // Convert loyaltyPoint to a number and add it
    }, 0);

    return totalLoyaltyPoints;
  };
  const calculateTaxes = () => {
    const subTotal = calculateSubTotal();
    const taxPercentage = parseFloat(checkoutData?.selected_taxes) || 0;
    const calculatedTax = subTotal * (taxPercentage / 100);
    const totalAmount = subTotal + calculatedTax;
    return calculatedTax;
  };

  const calculatePrice = () => {
    let applyDiscountBeforeTax = false;
    let basePrice = calculateSubTotal();
    let taxRate = parseFloat(checkoutData?.selected_taxes) || 0;
    let discount = parseFloat(checkoutData?.additonal_discount?.value) || 0;
    let discountType = checkoutData?.additonal_discount?.type || "%";
    let discountedPrice = basePrice;

    // Apply discount before tax
    if (applyDiscountBeforeTax) {
      if (discountType === "%") {
        discountedPrice = basePrice - basePrice * (discount / 100);
      } else {
        discountedPrice = basePrice - discount;
      }
    }

    // Calculate tax
    const tax = discountedPrice * (taxRate / 100);
    let finalPrice = discountedPrice + tax;

    // Apply discount after tax (if not applied before)
    if (!applyDiscountBeforeTax) {
      if (discountType === "%") {
        finalPrice -= finalPrice * (discount / 100);
      } else {
        finalPrice -= discount;
      }
    }
    let paymentEntriesArray = paymentEntries || [];
    let amountPaid = paymentEntriesArray.reduce(
      (sum, amount) => sum + (parseFloat(amount?.price) || 0),
      0
    );
    let amountDue =
      finalPrice -
      paymentEntriesArray.reduce(
        (sum, entry) => sum + (parseFloat(entry?.price) || 0),
        0
      );
    return { discountedPrice, tax, finalPrice, amountPaid, amountDue };
  };

  // Increment quantity handler
  const handleIncrement = (categoryKey, itemId) => {
    // Create a deep copy of salesData to avoid directly mutating it
    const updatedSalesData = salesData.map((category) => {
      if (category.category === categoryKey) {
        return {
          ...category,
          salesListing: {
            ...category.salesListing,
            [itemId]: calculateItemValues({
              ...category.salesListing[itemId],
              quantity: (category.salesListing[itemId]?.quantity || 0) + 1,
            }),
          },
        };
      }
      // if (category.category === categoryKey) {
      //   return {
      //     ...category,
      //     salesListing: {
      //       ...category.salesListing,
      //       [itemId]: {
      //         ...category.salesListing[itemId],
      //         quantity: (category.salesListing[itemId]?.quantity || 0) + 1,
      //       },
      //     },
      //   };
      // }
      return category; // Return the unchanged category
    });
    // Dispatch updated salesData to Redux
    dispatch(updateSalesData(updatedSalesData));
  };

  const handleDecrement = (categoryKey, itemId) => {
    // Create a deep copy of salesData to avoid directly mutating it
    const updatedSalesData = salesData.map((category) => {
      if (category.category === categoryKey) {
        const currentQuantity = category.salesListing[itemId]?.quantity || 0;
        return {
          ...category,
          salesListing: {
            ...category.salesListing,
            [itemId]: calculateItemValues({
              ...category.salesListing[itemId],
              quantity: Math.max(currentQuantity - 1, 0), // Ensure quantity doesn't go below 0,
            }),
          },
        };
      }
      // if (category.category === categoryKey) {
      //   const currentQuantity = category.salesListing[itemId]?.quantity || 0;
      //   return {
      //     ...category,
      //     salesListing: {
      //       ...category.salesListing,
      //       [itemId]: {
      //         ...category.salesListing[itemId],
      //         quantity: Math.max(currentQuantity - 1, 0), // Ensure quantity doesn't go below 0
      //       },
      //     },
      //   };
      // }
      return category; // Return the unchanged category
    });

    // Dispatch updated salesData to Redux
    dispatch(updateSalesData(updatedSalesData));
  };
  const handleResetQuantity = (categoryKey, itemId) => {
    // Create a deep copy of salesData to avoid directly mutating it
    const updatedSalesData = salesData.map((category) => {
      if (category.category === categoryKey) {
        return {
          ...category,
          salesListing: {
            ...category.salesListing,
            [itemId]: {
              ...category.salesListing[itemId],
              quantity: 0, // Set quantity to 0
            },
          },
        };
      }
      return category; // Return the unchanged category
    });

    // Dispatch updated salesData to Redux
    dispatch(updateSalesData(updatedSalesData));
  };

  const calculateItemValues = (item) => {
    const itemTotalPrice = item.price * item.quantity;

    let discountAmount = 0;
    if (item.discount.value === "%") {
      discountAmount = (item.discount.discountPrice / 100) * itemTotalPrice;
    } else {
      discountAmount = item.discount.discountPrice * item.quantity;
    }

    const itemFinalPrice = itemTotalPrice - discountAmount;

    return {
      ...item,
      itemTotalPrice,
      itemFinalPrice: Math.max(itemFinalPrice, 0), // Ensure the final price is not negative
      itemTotalDiscount: discountAmount,
    };
  };

  // Update quantity
  const updateQuantity = (categoryKey, itemId, newQuantity) => {
    const updatedSalesData = salesData.map((category) => {
      if (category.category === categoryKey) {
        return {
          ...category,
          salesListing: {
            ...category.salesListing,
            [itemId]: calculateItemValues({
              ...category.salesListing[itemId],
              quantity: newQuantity,
            }),
          },
        };
      }
      return category;
    });

    dispatch(updateSalesData(updatedSalesData));
  };

  // Update price
  const updatePrice = (categoryKey, itemId, newPrice) => {
    const updatedSalesData = salesData.map((category) => {
      if (category.category === categoryKey) {
        return {
          ...category,
          salesListing: {
            ...category.salesListing,
            [itemId]: calculateItemValues({
              ...category.salesListing[itemId],
              price: newPrice,
            }),
          },
        };
      }
      return category;
    });

    dispatch(updateSalesData(updatedSalesData));
  };

  // Update discount
  const updateDiscount = (
    categoryKey,
    itemId,
    newDiscountValue,
    newDiscountPrice
  ) => {
    const updatedSalesData = salesData.map((category) => {
      if (category.category === categoryKey) {
        return {
          ...category,
          salesListing: {
            ...category.salesListing,
            [itemId]: calculateItemValues({
              ...category.salesListing[itemId],
              discount: {
                ...category.salesListing[itemId].discount,
                value: newDiscountValue,
                discountPrice: newDiscountPrice,
              },
            }),
          },
        };
      }
      return category;
    });

    dispatch(updateSalesData(updatedSalesData));
  };
  const handleStaffSelect = (categoryKey, itemId, staffId, isChecked) => {
    // Create a deep copy of salesData to avoid directly mutating it
    const updatedSalesData = salesData.map((category) => {
      if (category.category === categoryKey) {
        return {
          ...category,
          salesListing: {
            ...category.salesListing,
            [itemId]: {
              ...category.salesListing[itemId],
              staffList: category.salesListing[itemId]?.staffList?.map(
                (staff) =>
                  staff.id === staffId
                    ? { ...staff, isSelected: isChecked } // Toggle the isSelected value
                    : staff
              ),
            },
          },
        };
      }
      return category; // Return the unchanged category
    });

    // Dispatch updated salesData to Redux
    dispatch(updateSalesData(updatedSalesData));
  };
  const showStaffNames = (staffList) => {
    return staffList
      .filter((staff) => staff.isSelected)
      .map((staff) => staff.staff_name)
      .join(" + ");
  };

  const clearCart = () => {
    const updatedSalesData = salesData.map((category) => ({
      ...category,
      salesListing: Object.fromEntries(
        Object.entries(category.salesListing).map(([itemId, item]) => [
          itemId,
          { ...item, quantity: 0 },
        ])
      ),
    }));

    dispatch(updateSalesData(updatedSalesData));
    // reset the payment entries
    setPaymentEntries([{ id: Date.now(), price: "", paymentMode: "Cash" }]);
  };

  const renderItems = () => {
    // Flatten the sales data for easy mapping in the table
    const itemsWithQuantity = flattenItemsArray(); // Only include items with quantity > 0
    return (
      <>
        {itemsWithQuantity.map((item, index) => (
          <>
            <div className="vstack border-bottom border-top px-4">
              <div className="hstack mt-4">
                <h6 className="">{item?.name}</h6>
                <div className="ms-auto">
                  <div className="hstack gap-2">
                    <>
                      {getCurrencySymbolTextforTd(item?.itemFinalPrice)}
                      <Button
                        variant=""
                        className="bg-danger-light text-secondary p-0 w-5 h-5 d-flex align-items-center justify-content-center rounded-circle"
                        onClick={() =>
                          handleResetQuantity(item.categoryKey, item.itemId)
                        }
                      >
                        <IoCloseSharp className="text-xs" />
                      </Button>
                    </>
                  </div>
                </div>
              </div>
              <div className="d-flex gap-4 mt-4">
                <div className="item-row">
                  <div className="floating-label-group position-relative mb-3">
                    <InputGroup size="sm" className="d-flex flex-nowrap">
                      {/* <InputGroup.Text className="bg-transparent">%</InputGroup.Text> */}
                      <Form.Control
                        type="text"
                        value={item?.quantity}
                        size="sm"
                        className="px-1 text-center"
                        onChange={(e) =>
                          updateQuantity(
                            item.categoryKey,
                            item.itemId,
                            parseInt(e.target.value) || 0
                          )
                        }
                      />
                    </InputGroup>

                    <label className="floating-label">Qty</label>
                  </div>
                </div>
                <div className="item-row">
                  <div className="floating-label-group position-relative mb-3">
                    <InputGroup size="sm" className="d-flex flex-nowrap">
                      <InputGroup.Text
                        id="basic-addon1"
                        className="px-1 text-dark bg-light bg-transparent"
                      >
                        {getCurrencySymbolIcon()}
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        value={item?.price}
                        size="sm"
                        className="px-1 text-center"
                        onChange={(e) =>
                          updatePrice(
                            item.categoryKey,
                            item.itemId,
                            parseFloat(e.target.value) || 0
                          )
                        }
                      />
                    </InputGroup>

                    <label className="floating-label">Price</label>
                  </div>
                </div>
                <div className="item-row">
                  <div className="floating-label-group position-relative mb-3">
                    <InputGroup size="sm" className="d-flex flex-nowrap">
                      <Form.Control
                        type="text"
                        value={item?.discount?.discountPrice}
                        size="sm"
                        className="px-1 text-center"
                        onChange={(e) =>
                          updateDiscount(
                            item.categoryKey,
                            item.itemId,
                            item.discount.value,
                            parseFloat(e.target.value) || 0
                          )
                        }
                      />
                      <Form.Select
                        aria-label="Default select example"
                        className="pe-8 ps-0 text-end border-start-0"
                        style={{ minWidth: "50px" }} // Ensuring a minimum width
                        name="discount_type"
                        value={item?.discount?.value}
                        onChange={(e) =>
                          updateDiscount(
                            item.categoryKey,
                            item.itemId,
                            e.target.value,
                            item.discount.discountPrice
                          )
                        }
                      >
                        <option value="%">%</option>
                        <option value={getCurrencySymbolTextForDropdown()}>
                          {getCurrencySymbolText()}
                        </option>
                      </Form.Select>
                    </InputGroup>

                    <label className="floating-label">Discount</label>
                  </div>
                </div>
                <div className="item-row">
                  <Dropdown
                    // as={ButtonGroup}
                    className="bg-light dropdown-toggle-remove w-full position-static"
                    autoClose="outside"
                  >
                    <Dropdown.Toggle
                      id="dropdown-autoclose-outside"
                      // className="bg-light border-0 p-0 w-full"
                      className="bg-light border-0 p-0 w-full"
                    >
                      <div className="floating-label-group position-relative mb-3">
                        <InputGroup size="sm" className="d-flex flex-nowrap">
                          <Form.Control
                            type="text"
                            value={showStaffNames(item?.staffList)}
                            size="sm"
                            className="px-1 text-center"
                            // onChange={onChange}
                            readOnly
                          />
                          <InputGroup.Text className="border-0  bg-transparent pe-1 ps-3">
                            <FaAngleDown />
                          </InputGroup.Text>
                        </InputGroup>

                        <label className="floating-label">
                          Staff allocated
                        </label>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="">
                      {/* removed  className="w-full"*/}
                      {item?.staffList?.map((staff, StaffIndex) => (
                        <Dropdown.Item key={staff?.id}>
                          <Form.Check type="checkbox" id={`staff_${staff?.id}`}>
                            <Form.Check.Input
                              type="checkbox"
                              checked={staff?.isSelected}
                              onChange={(e) =>
                                handleStaffSelect(
                                  item.categoryKey,
                                  item.itemId,
                                  staff?.id,
                                  e.target.checked
                                )
                              }
                            />
                            <Form.Check.Label className="ms-2 text-wrap">
                              {staff?.staff_name}
                            </Form.Check.Label>
                          </Form.Check>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </>
        ))}
      </>
    );
  };

  const [giveRewardPoints, setGiveRewardPoints] = useState(false);

  const handleGenerateinvoice = (draft = false) => {
    // console.log("---->",checkoutData , flattenItemsArray());
    const services = flattenItemsArray()?.filter(
      (item) => item?.categoryKey === "Services"
    );
    const products = flattenItemsArray()?.filter(
      (item) => item?.categoryKey === "Products"
    );
    const memberships = flattenItemsArray()?.filter(
      (item) => item?.categoryKey === "Memberships"
    );
    const packages = flattenItemsArray()?.filter(
      (item) => item?.categoryKey === "Packages"
    );
    // console.log(services);

    let payload = {
      date: JSON.stringify(
        convertTimeToUTC(
          moment(checkoutData?.date).format("DD-MM-YYYY"),
          moment(checkoutData?.date).format("hh:ss A")
        )
      ),
      services: JSON.stringify(services || []),
      products: JSON.stringify(products || []),
      memberships: JSON.stringify(memberships || []),
      packages: JSON.stringify(packages || []),
      client_number: checkoutData?.clientData?.phone,
      client_name: checkoutData?.clientData?.name,
      subTotal: JSON.stringify(calculateSubTotal()),
      amount_due: JSON.stringify(calculatePrice().amountDue),
      amount_paid: JSON.stringify(calculatePrice().amountPaid),
      amount_payable: JSON.stringify(calculatePrice().finalPrice),
      taxes: JSON.stringify(calculatePrice().tax),
      selected_taxes: checkoutData?.selected_taxes,
      additonal_discount: JSON.stringify(checkoutData?.additonal_discount),
      payment_mode: JSON.stringify(paymentEntries),
      reward_points: JSON.stringify(countLoyaltyPoints()),
      notes: checkoutData?.notes,
      isSaveDraft: JSON.stringify(draft),
    };

    let payload2 = {
      date: convertTimeToUTC(
        moment(checkoutData?.date).format("DD-MM-YYYY"),
        moment(checkoutData?.date).format("hh:ss A")
      ),
      services: services || [],
      products: products || [],
      memberships: memberships || [],
      packages: packages || [],
      client_number: checkoutData?.clientData?.phone || null,
      client_name: checkoutData?.clientData?.name || null,
      subTotal: calculateSubTotal(),
      amount_due: calculatePrice().amountDue,
      amount_paid: calculatePrice().amountPaid,
      amount_payable: calculatePrice().finalPrice,
      taxes: calculatePrice().tax,
      selected_taxes: checkoutData?.selected_taxes,
      additonal_discount: checkoutData?.additonal_discount,
      payment_mode: paymentEntries,
      reward_points: countLoyaltyPoints(),
      notes: checkoutData?.notes,
      isSaveDraft: draft,
    };
    console.log("payload->", payload);

    // dispatch(addCheckout(payload2));

    dispatch(editCheckout({id , data : payload2})).then((action) => {
      if (action.meta.requestStatus === "fulfilled") {
        clearCart();
        dispatch(clearCheckoutData());
        navigate("/salesNew/entries");
      } else {
        toast.error("Error creating checkout");
      }
    });
  };

  // //////////////////////////////////////////////////

  //  Edit Flow //

  // //////////////////////////////////////////////////
  //  const setAllCheckoutDetailes = (responseData) => {
  //     let items = [];

  //     const productData = responseData?.data?.checkoutDetails?.products?.map(
  //       (item) => {
  //         return {
  //           ...item,
  //           name: item?.name,
  //           price: item?.price,
  //           quantity: item?.quantity,
  //           discount: item?.discount,
  //           // service_details: { staff: item?.product_details?.staff },
  //           category: "Products",
  //         };
  //       }
  //     );
  //     const serviceData = responseData?.data?.checkoutDetails?.services?.map(
  //       (item) => {
  //         return {
  //           ...item,
  //           name: item?.service_subService_name,
  //           price: item?.price,
  //           discount: item?.discount,
  //           quantity: item?.quantity,
  //         };
  //       }
  //     );
  //     const membershipData =
  //       responseData?.data?.checkoutDetails?.memberships?.map((item) => {
  //         return {
  //           ...item,
  //           name: item?.service_details?.name,
  //           price: item?.service_details?.price,
  //           quantity: item?.service_details?.quantity,
  //           discount: item?.service_details?.discount,
  //           memberships_category: item?.category,
  //           category: "Memberships",
  //         };
  //       });
  //     const packageData = responseData?.data?.checkoutDetails?.packages?.map(
  //       (item) => {
  //         const subServices = item?.sub_service_info.map((subItem) => ({
  //           ...subItem,
  //         }));
  //         return {
  //           ...item,
  //           name: item?.name,
  //           price: item?.price,
  //           quantity: item?.quantity,
  //           discount: item?.discount,
  //           isPackage: true,
  //           category: "Packages",
  //           sub_service_info: subServices,
  //         };
  //       }
  //     );
  //     items = [...productData, ...serviceData, ...membershipData, ...packageData];
  //     setSelectedItems(items);
  //     // ///////////////

  //     setClientSearchInput(responseData?.data?.checkoutDetails?.client_number);
  //     setSelectedClient(responseData?.data?.clientData);

  //       setBillingDate(formatDateFromISO(convertUTCToUserTimeZone(responseData?.data?.checkoutDetails?.billingDate)));
  //       setBillingTime(formatTimeFromISO(convertUTCToUserTimeZone(responseData?.data?.checkoutDetails?.billingTime)));
  //     const tax_value =
  //       responseData?.data?.checkoutDetails?.taxes === 0 ||
  //       responseData?.data?.checkoutDetails?.taxes === "0"
  //         ? "0"
  //         : "products services memberships packages";
  //     setSelectedTax(tax_value);
  //     setAdditionalDiscount(
  //       responseData?.data?.checkoutDetails?.additonal_discount
  //     );

  //     setCouponCode(responseData?.data?.checkoutDetails?.coupon_code);
  //     const rewardPointValue =
  //       Number(responseData?.data?.checkoutDetails?.reward_points) === 0
  //         ? false
  //         : true;
  //     setPaymentEntries(responseData?.data?.checkoutDetails?.paymentMode);

  //     setGiveRewardPoints(rewardPointValue);
  //   };

 const fetchClientSearchResults = async (clientNumber) => {
  if (clientNumber) {
    
 
    const backend = new API();
    try {
      dispatch(loadingStart());
      const response = await backend.fetch(API.getClient(clientNumber), {
        headers: {
          Accept: "multipart/form-data",
          "access-control-allow-origin": "*",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          'X-Logged-branch-ID': localStorage.getItem("branchId") || "undefined",
        },
      });
      const data = await response?.data;
      return data?.[0] || {};
      
      // setClientSearchResults(data);
    } catch (error) {
      console.error("Error fetching search results:", error);
      return {};
    } finally {
      dispatch(loadingEnd());
    } }
    else {
      return {};
    }
  };

  const transformCheckoutDetailsToSalesList = async (data) => {
    const checkoutDetails = checkoutDetailes;
    // console.log(checkoutDetailes);

    const transformedData = [];

    // Map services
    if (checkoutDetails?.services) {
      transformedData.push({
        category: "Services",
        categoryKey: "services",
        salesListing: Object.fromEntries(
          Object.entries(checkoutDetails.services).map(([id, item]) => [
            id,
            {
              ...item,
              quantity: item.quantity || 0,
              discount: item.discount || {
                label: "%",
                value: "%",
                discountPrice: 0,
              },
            },
          ])
        ),
      });
    }

    // Map products
    if (checkoutDetails?.products) {
      transformedData.push({
        category: "Products",
        categoryKey: "products",
        salesListing: Object.fromEntries(
          Object.entries(checkoutDetails.products).map(([id, item]) => [
            id,
            {
              ...item,
              quantity: item.quantity || 0,
              discount: item.discount || {
                label: "%",
                value: "%",
                discountPrice: 0,
              },
            },
          ])
        ),
      });
    }

    // Map memberships
    if (checkoutDetails?.memberships) {
      transformedData.push({
        category: "Memberships",
        categoryKey: "memberships",
        salesListing: Object.fromEntries(
          Object.entries(checkoutDetails.memberships).map(([id, item]) => [
            id,
            {
              ...item,
              quantity: item.quantity || 0,
              discount: item.discount || {
                label: "%",
                value: "%",
                discountPrice: 0,
              },
            },
          ])
        ),
      });
    }

    // Map packages
    if (checkoutDetails?.packages) {
      transformedData.push({
        category: "Packages",
        categoryKey: "packages",
        salesListing: Object.fromEntries(
          Object.entries(checkoutDetails.packages).map(([id, item]) => [
            id,
            {
              ...item,
              quantity: item.quantity || 0,
              discount: item.discount || {
                label: "%",
                value: "%",
                discountPrice: 0,
              },
            },
          ])
        ),
      });
    }
    // console.log("vgvgv",transformedData);
    dispatch(updateSalesData(transformedData));

    // set other Fields
    let newCheckoutData = {
      date: new Date(convertUTCToUserTimeZone(checkoutDetailes?.date)),
      clientData: await fetchClientSearchResults(checkoutDetailes?.client_number),
      additonal_discount: checkoutDetailes?.additonal_discount,
      payment_mode: checkoutDetailes?.payment_mode,
      notes: checkoutDetailes?.notes || '', 
      selected_taxes: checkoutDetailes?.selected_taxes || "18",
    };
    dispatch(updateCheckoutData(newCheckoutData));
    const rewardPointValue =
        Number(checkoutDetailes?.reward_points) === 0
          ? false
          : true;
      setGiveRewardPoints(rewardPointValue);
   
    return transformedData;
  };

  useEffect(() => {
    if (checkoutDetailes && isObjectEmpty(checkoutDetailes)) {
      dispatch(fetchCheckoutDetails(id));
    } else {
      transformCheckoutDetailsToSalesList();
    }
  }, [checkoutDetailes, dispatch]);

  useEffect(() => {
    if (!id) {
      navigate("/salesNew/entries");
    } else {
      dispatch(fetchCheckoutDetails(id));
      // .then((action) => {
      //   if (action.meta.requestStatus === "fulfilled") {
      //     // setAllCheckoutDetailes(action?.payload); // Assuming payload has the form structure you need
      //     // dispatch(updateSalesData(transformCheckoutDetailsToSalesList())) ; // Assuming payload has the form structure you need
      //     transformCheckoutDetailsToSalesList(action?.payload) ; // Assuming payload has the form structure you need
      //     // setLoading(false);
      //   } else {
      //     // setLoading(false);
      //     console.error("Error fetching record data:", action.payload);
      //   }
      // });
    }
  }, [id, dispatch, navigate]);
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   dispatch(updateRecordSale({ data: formData, id })).then((action) => {
  //     if (action.meta.requestStatus === "fulfilled") {
  //       navigate("/sales/entries");
  //     } else {
  //       console.error("Error updating record sale:", action.payload);
  //     }
  //   });
  // };

  const modulesPermissions = useSelector(
    (state) => state?.authSession?.modulesPermissions
  );
  if (loading) {
    return (
      <>
        <PageLoader />
        <CommonLayout>
          <PermissionGuard
            hasPermission={modulesPermissions?.billing?.sales?.is_create}
          >
            <Row></Row>
          </PermissionGuard>
        </CommonLayout>
      </>
    );
  }

  if (!checkoutDetailes) {
  // if (false) {
    return (
      <CommonLayout>
        <PermissionGuard
          hasPermission={modulesPermissions?.billing?.sales?.is_create}
        >
          <Row>
            <Alert variant="danger" onClick={() => navigate("/salesNew/entries")}>
              Please select an invoice to edit.
            </Alert>
          </Row>
        </PermissionGuard>
      </CommonLayout>
    );
  }
  return (
    <div>
      <CommonLayout>
        <PermissionGuard
          hasPermission={modulesPermissions?.billing?.sales?.is_create}
        >
          <div className="main-container">
            {/* Header */}
            <Row className="mb-7 d-flex align-items-center justify-content-between">
              <Col>
                <h4 className="mb-2">Edit sale</h4>
                <p>Edit your sales here and generate invoices</p>
              </Col>

              <Col xs="auto" className="mb-0">
                <div className="hstack gap-3 ">
                  <div className="datepicker-staff">
                    {/* <DatePicker
                        wrapperClassName="w-full date-icon-add"
                        // selected={formData.date}
                        // value={}
                        className="text-test"
                        placeholderText="Select a date"
                        // onChange={(date) => handleDateChange(date)}
                        isClearable={true}
                      /> */}
                    <DateTime
                      //  value={billingDate}
                      value={checkoutData?.date}
                      input={true} // Enable the input field
                      dateFormat="DD-MM-YYYY" // Date format
                      timeFormat={false} // Disable the time picker
                      //  onChange={(time) =>
                      //    handleDateChange("billingDate", time, true)
                      //  }
                      onChange={(time) => {
                        let newCheckoutData = {
                          ...checkoutData,
                          date: time,
                        };
                        dispatch(updateCheckoutData(newCheckoutData));
                      }}
                      inputProps={{ placeholder: "DD-MM-YYYY" }}
                    />
                  </div>
                  <div className="datepicker-staff">
                    <DateTime
                      // value={billingTime}
                      value={checkoutData?.date}
                      // onChange={(time) =>
                      //   handleDateChange("billingTime", time, false)
                      // }
                      onChange={(time) => {
                        let newCheckoutData = {
                          ...checkoutData,
                          date: time,
                        };
                        dispatch(updateCheckoutData(newCheckoutData));
                      }}
                      inputProps={{ placeholder: "--:-- --" }}
                      input={true} // Disable the input field
                      dateFormat={false} // Disable the date picker
                      timeFormat="hh:mm A" // 12-hour format with AM/PM
                    />
                  </div>
                </div>
              </Col>
            </Row>

            {/* Main Content */}
            <div className="content">
              {/* Left Section */}
              <div className="left-section">
                {/* Search bar */}
                <div>
                  <InputGroup className="form-group-1 border rounded-0 me-auto w-full">
                    <InputGroup.Text className="border-0  bg-transparent pe-1 ps-3 disabled">
                      <BiSearch className="text-lg" />
                    </InputGroup.Text>

                    <FormControl
                      className="border-0 "
                      type="text"
                      name="search"
                      // value={searchInput}
                      // onChange={handleSearchInputChange}
                      placeholder="Search by name"
                      // disabled={!selectedClient}
                    />
                    <InputGroup.Text
                      className="bg-light text-primary"
                      // onClick={() => setShowBarcodeScanModel(true)}
                    >
                      {/* <i className="fas fa-barcode"></i> */}
                      <RiBarcodeFill />
                    </InputGroup.Text>
                  </InputGroup>
                </div>
                <div className="scrollable-content">
                  {/* Add your card components here */}
                  {/* Listing */}
                  <div className="my-ui-component d-none">
                    <Tabs
                      activeKey={activeTab}
                      onSelect={(key) => setActiveTab(key)}
                      id="main-tabs"
                      className="mb-3"
                    >
                      {mainCategories?.map((mainCategory) => (
                        <Tab
                          key={mainCategory?.value}
                          eventKey={mainCategory?.value}
                          title={mainCategory?.label}
                          className="scrollable-content"
                        >
                          <Tab.Container defaultActiveKey="BestSeller">
                            <Row className="vertical-tabs-container mx-0">
                              {/* Vertical Tabs */}
                              <Col sm={3} className="tabs-nav px-0">
                                <Nav variant="pills" className="flex-column">
                                  {categories
                                    ?.filter(
                                      (category) =>
                                        category?.category ===
                                          mainCategory?.value &&
                                        category?.sub_category?.length > 0
                                    )
                                    ?.map((category) =>
                                      category?.sub_category?.map(
                                        (subcategory) => (
                                          <Nav.Item key={subcategory?.key}>
                                            <Nav.Link
                                              eventKey={subcategory?.key}
                                              className="py-2 px-2 text-center"
                                            >
                                              <div className="icon mb-1">
                                                <Image
                                                  src={
                                                    subcategory?.selected_image ||
                                                    subcategory?.image
                                                  }
                                                  width={32}
                                                  className="mx-auto"
                                                />
                                              </div>
                                              <div className="label">
                                                {subcategory?.name}
                                              </div>
                                            </Nav.Link>
                                          </Nav.Item>
                                        )
                                      )
                                    )}
                                </Nav>
                              </Col>

                              {/* Tab Content */}
                              <Col sm={9} className="tabs-content px-0">
                                <Tab.Content>
                                  {salesData
                                    ?.filter(
                                      (item) =>
                                        item?.category === mainCategory?.value
                                    )
                                    ?.map((item) =>
                                      Object.entries(item?.salesListing).map(
                                        ([key, value]) => (
                                          <Tab.Pane
                                            key={key}
                                            eventKey={value.category_type}
                                          >
                                            <Row>
                                              <Col
                                                xs={12}
                                                md={6}
                                                lg={4}
                                                className="mb-4"
                                              >
                                                <Card className="h-100">
                                                  <Card.Img
                                                    variant="top"
                                                    src={value.image}
                                                    alt={value.name}
                                                    style={{
                                                      height: "120px",
                                                      objectFit: "cover",
                                                    }}
                                                  />
                                                  <Card.Body>
                                                    <Card.Title className="text-truncate">
                                                      {value.name}
                                                    </Card.Title>
                                                    <Stack direction="horizontal">
                                                      <p>
                                                        {getCurrencySymbolTextforTd(
                                                          value?.price || 0
                                                        )}
                                                      </p>
                                                      <div className="ms-auto d-flex align-items-center">
                                                        {value.quantity > 0 ? (
                                                          <>
                                                            <Button
                                                              variant="outline-primary"
                                                              size="sm"
                                                              className="py-1 px-2"
                                                            >
                                                              <HiOutlineMinusSmall />
                                                            </Button>
                                                            <span className="mx-2">
                                                              {value.quantity}
                                                            </span>
                                                            <Button
                                                              variant="outline-primary"
                                                              size="sm"
                                                              className="py-1 px-2"
                                                            >
                                                              <HiOutlinePlusSmall />
                                                            </Button>
                                                          </>
                                                        ) : (
                                                          <Button
                                                            variant="primary"
                                                            size="sm"
                                                            className="py-1 px-2"
                                                          >
                                                            <HiOutlinePlusSmall />
                                                          </Button>
                                                        )}
                                                      </div>
                                                    </Stack>
                                                  </Card.Body>
                                                </Card>
                                              </Col>
                                            </Row>
                                          </Tab.Pane>
                                        )
                                      )
                                    )}
                                </Tab.Content>
                              </Col>
                            </Row>
                          </Tab.Container>
                        </Tab>
                      ))}
                    </Tabs>
                  </div>
                  <div className="">
                    <Tabs
                      activeKey={activeTab}
                      onSelect={(key) => {
                        setActiveTab(key);
                      }}
                      id="noanim-tab-example"
                      className="mb-0 mt-8 tabs-type-1"
                    >
                      {mainCategories?.map((mainCategory, index) => (
                        <Tab
                          eventKey={mainCategory?.value}
                          title={mainCategory?.label}
                          className="scrollable-content"
                        >
                          <div className="scrollable-content">
                            <div>
                              <Tab.Container defaultActiveKey="BestSeller">
                                <Row className="vertical-tabs-container mx-0">
                                  {/* Left Side - Tabs Navigation */}
                                  {categories?.map((category, index) => {
                                    if (
                                      category?.category ===
                                        mainCategory?.value &&
                                      category?.sub_category.length > 0
                                    ) {
                                      return (
                                        <>
                                          <Col sm={3} className="tabs-nav px-0">
                                            <Nav
                                              variant="pills"
                                              className="flex-column"
                                              // className="vstack"
                                            >
                                              {category?.sub_category && (
                                                <>
                                                  {category?.sub_category?.map(
                                                    (subcategory, subIndex) => (
                                                      <Nav.Item>
                                                        <Nav.Link
                                                          eventKey={
                                                            subcategory?.key
                                                          }
                                                          className="py-2 px-2 text-semibold vstack"
                                                        >
                                                          <div className="icon">
                                                            <Image
                                                              className="mx-auto"
                                                              width={32}
                                                              src={
                                                                false
                                                                  ? subcategory?.selected_image
                                                                  : subcategory?.image
                                                              }
                                                            />
                                                          </div>
                                                          <div className="label mx-auto text-center">
                                                            {subcategory?.name}
                                                          </div>
                                                        </Nav.Link>
                                                      </Nav.Item>
                                                    )
                                                  )}
                                                </>
                                              )}
                                            </Nav>
                                          </Col>
                                        </>
                                      );
                                    }
                                  })}

                                  {/* Right Side - Tab Content */}
                                  <Col sm={9} className="tabs-content px-0">
                                    <Row className="mx-0 px-0">
                                      {salesData?.map((item, index) => {
                                        if (
                                          item?.category === mainCategory?.value
                                        ) {
                                          return (
                                            <Tab.Content>
                                              {/* {Object.entries(item?.salesListing).map(
                                        ([key, value]) => {
                                          console.log(key, value);
                                          return (
                                         <></>
                                          );
                                        }
                                      )} */}
                                              {Object.entries(
                                                item?.salesListing
                                              )
                                                // .filter(([key, value]) => value.category_type === filterCategory) // Filter by category_type
                                                .map(([key, value]) => (
                                                  <>
                                                    <Tab.Pane
                                                      eventKey={
                                                        value.category_type
                                                      }
                                                      className="xxxxxxxxxxxxxxxxxx"
                                                    >
                                                      <Col
                                                        xs={12}
                                                        md={6}
                                                        lg={6}
                                                        key={value.id}
                                                        className="px-0"
                                                      >
                                                        <Card
                                                          // className="h-100"
                                                          className="m-4"
                                                        >
                                                          <Card.Img
                                                            variant="top"
                                                            src={value.image}
                                                            alt={value.name}
                                                            style={{
                                                              // width: '120px',
                                                              height: "75px",
                                                              objectFit:
                                                                "cover",
                                                            }}
                                                          />
                                                          <Card.Body className="p-2">
                                                            <Card.Title className="text-truncate text-sm mb-4">
                                                              {value.name}
                                                            </Card.Title>
                                                            {/* <Card.Text>₹{value.price}</Card.Text> */}
                                                            <Stack direction="horizontal">
                                                              {" "}
                                                              <p>
                                                                {getCurrencySymbolTextforTd(
                                                                  value?.price ||
                                                                    0
                                                                )}{" "}
                                                              </p>{" "}
                                                              <div className="d-flex align-items-center ms-auto">
                                                                {value.quantity >
                                                                0 ? (
                                                                  <>
                                                                    <Button
                                                                      variant="outline-primary"
                                                                      size="sm"
                                                                      className="py-1 px-1 d-flex align-items-center gap-1"
                                                                      onClick={() =>
                                                                        handleDecrement(
                                                                          item?.category,
                                                                          key
                                                                        )
                                                                      }
                                                                    >
                                                                      <HiOutlineMinusSmall />
                                                                    </Button>
                                                                    <span className="mx-2">
                                                                      {
                                                                        value.quantity
                                                                      }
                                                                    </span>
                                                                    <Button
                                                                      variant="outline-primary"
                                                                      size="sm"
                                                                      className="py-1 px-1 d-flex align-items-center gap-1"
                                                                      onClick={() =>
                                                                        handleIncrement(
                                                                          item?.category,
                                                                          key
                                                                        )
                                                                      }
                                                                    >
                                                                      <HiOutlinePlusSmall />
                                                                    </Button>
                                                                  </>
                                                                ) : (
                                                                  <Button
                                                                    variant="primary"
                                                                    size="sm"
                                                                    className="py-1 px-1 d-flex align-items-center gap-1"
                                                                    onClick={() =>
                                                                      handleIncrement(
                                                                        item?.category,
                                                                        key
                                                                      )
                                                                    }
                                                                    s
                                                                  >
                                                                    <HiOutlinePlusSmall />
                                                                  </Button>
                                                                )}
                                                              </div>
                                                            </Stack>
                                                          </Card.Body>
                                                        </Card>
                                                      </Col>
                                                    </Tab.Pane>
                                                  </>
                                                ))}
                                            </Tab.Content>
                                          );
                                        }
                                      })}
                                    </Row>
                                  </Col>
                                </Row>
                              </Tab.Container>
                            </div>
                          </div>
                        </Tab>
                      ))}
                      {/* <Tab className="text-primary" title={'+Create New'}><h6 className="text-primary"> +Create New</h6></Tab> */}
                    </Tabs>
                    {/* <p className="text-primary text-sm"> +Create New</p> */}
                  </div>
                </div>
              </div>

              {/* Right Section */}
              <div className="right-section">
                {/* Cart Section */}
                <div className="cart-section">
                  {/* <h3>Cart</h3> */}
                  <div className="d-flex px-4">
                    <div>
                      <h5>Cart</h5>
                    </div>
                    <div className="ms-auto d-flex gap-1">
                      <Button
                        variant="transparent"
                        size="sm"
                        className="border py-1 px-2 text-xs d-flex align-items-center gap-1"
                        onClick={() => handleGenerateinvoice(true)}
                      >
                        <BiSave size={16} /> Save as Draft
                      </Button>
                      <Button
                        variant="transparent"
                        size="sm"
                        className="border py-1 px-2 text-xs d-flex align-items-center gap-1"
                        onClick={clearCart}
                      >
                        <AiOutlineClear size={16} /> Clear cart
                      </Button>
                      <Button
                        variant="transparent"
                        size="sm"
                        className="border py-1 px-2 text-xs d-flex align-items-center gap-1"
                        onClick={() => setShowAddNoteModel(true)}
                      >
                        <PiNote size={16} /> Add Note
                      </Button>
                    </div>
                  </div>
                  {/* select client */}
                  <div className="hstack gap-2 w-full d-flex justify-content-end mt-4 px-4">
                    {isObjectEmpty(checkoutData?.clientData) ? (
                      <Button
                        variant="transparent"
                        size="sm"
                        className="w-full btn-outline-secondary border-dashed rounded-1 text-sm"
                        style={{ border: "1px dashed" }}
                        // onClick={handleAddService}
                        onClick={() =>
                          setShowOffcanvasSelectClient(
                            !showOffcanvasSelectClient
                          )
                        }
                      >
                        <BiPlus className="text-md me-2" />
                        Select a client (Optional)
                      </Button>
                    ) : (
                      <div className="w-full bg-grey hstack gap-2 p-2">
                        <div>
                          <div>
                            <a
                              href="#"
                              className="text-decoration-underline text-sm"
                            >
                              {" "}
                              {checkoutData?.clientData?.name}{" "}
                            </a>{" "}
                            |{" "}
                            <span className="text-sm text-dark">
                              {checkoutData?.clientData?.phone}
                            </span>{" "}
                            <span
                              className={`badge badge-sm border py-0 px-1 text-dark   text-xs font-regular ${getClassNameForClientTag(
                                checkoutData?.clientData?.tag
                              )}`}
                            >
                              {checkoutData?.clientData?.tag}
                            </span>{" "}
                          </div>
                          <div className="hstack gap-4">
                            <div className="hstack gap-2">
                              <Image src={TwoCoins} width={16} />
                              <p className="text-dark text-xs">
                                Loyalty points{" "}
                                {/* <span className="text-md font-bold">15000 </span> */}
                                <span className="text-xs font-bold">
                                  {checkoutData?.clientData?.reward_points || 0}{" "}
                                </span>
                              </p>
                            </div>
                            <div className="hstack gap-2">
                              <Image src={Cash} width={16} />
                              <p className="text-dark text-xs">
                                {/* Balance <span className="text-md font-bold">₹15000 </span> */}
                                Balance{" "}
                                <span className="text-xs font-bold">
                                  {getCurrencySymbolTextforTd(
                                    checkoutData?.clientData?.balance || 0
                                  )}{" "}
                                </span>
                              </p>
                            </div>
                            <div className="hstack gap-2">
                              <Image src={GoldCoin} width={16} />
                              <p className="text-dark text-xs">
                                {/* Balance <span className="text-md font-bold">₹15000 </span> */}
                                Membership Balance{" "}
                                <span className="text-xs font-bold">
                                  {getCurrencySymbolTextforTd(
                                    checkoutData?.clientData?.service_balance ||
                                      0
                                  )}{" "}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="ms-auto p-2"
                          // onClick={(e) => {
                          //   let newCheckoutData = {
                          //     ...checkoutData,
                          //     clientData: {},
                          //   };
                          //   dispatch(updateCheckoutData(newCheckoutData));
                          // }}
                        >
                          {/* <IoClose /> */}
                        </div>
                        {/* clientData */}
                      </div>
                    )}
                  </div>
                  {/* Loyalty points */}
                  <div className="mt-4 px-4">
                    <Stack
                      direction="horizontal"
                      className="justify-content-between mb-2"
                    >
                      <div>
                        <p className="mb-1 text-dark text-sm">
                          Give reward points{" "}
                        </p>
                        <p className="text-xs">
                          *Loyalty points:- 1 points = 1.00 INR.
                        </p>
                      </div>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        checked={giveRewardPoints}
                        disabled
                        // onChange={(e) => setGiveRewardPoints(e.target.checked)}
                      />
                    </Stack>
                    {giveRewardPoints && (
                      <Alert
                        key="success"
                        variant="success"
                        className="py-2 px-4 text-xs rounded-0 border-0 mb-2"
                      >
                        {checkoutData?.clientData?.name
                          ? `${checkoutData?.clientData?.name} `
                          : "Customer "}
                        is getting {countLoyaltyPoints()} loyalty points on this
                        bill
                        {/* Customer is getting 0 loyalty points on this bill */}
                      </Alert>
                    )}
                  </div>
                </div>
                <div className="scrollable-content">
                  {/* Selected Item Listing */}
                  {/* <div className="mt-4 px-0"> */}
                  {renderItems()}
                  {/* </div> */}
                </div>

                {/* Payment Section */}
                <div className="scrollable-content">
                  <>
                    <div className="vstack border-top px-4 pt-4 gap-2">
                      <div className="hstack">
                        <div>
                          {" "}
                          <p className="text-sm">Item sub-total</p>
                        </div>
                        <div className="ms-auto">
                          <p className="text-sm text-dark">
                            {getCurrencySymbolTextforTd(
                              calculateSubTotal().toFixed(2)
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="hstack">
                        <div>
                          {/* <p className="text-sm">Taxes @</p>  */}
                          <FormGroup className="hstack gap-3">
                            <p className="text-sm">Taxes</p>

                            <Form.Select
                              className="form-select-sm py-1 text-xs"
                              size="sm"
                              value={checkoutData?.selected_taxes}
                              // onChange={handleTaxChange}
                              defaultValue={"Choose..."}
                              onChange={(e) => {
                                let newCheckoutData = {
                                  ...checkoutData,
                                  selected_taxes: e.target.value,
                                };
                                dispatch(updateCheckoutData(newCheckoutData));
                              }}
                            >
                              <option disabled>Choose...</option>
                              {taxOptionsLables.map((option, index) => (
                                <option key={index} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Form.Select>
                          </FormGroup>
                        </div>
                        <div className="ms-auto">
                          <p className="text-sm text-dark">
                            {getCurrencySymbolTextforTd(
                              calculatePrice().tax.toFixed(2)
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="hstack">
                        <div className="floating-label-group d-flex align-items-center">
                          <p className="text-sm">Additional discount</p>
                          <div className="w-1/4 ms-2">
                            <InputGroup
                              size="sm"
                              className="d-flex flex-nowrap rounded-0"
                              style={{ height: "22px" }}
                            >
                              <Form.Control
                                type="text"
                                value={checkoutData?.additonal_discount?.value}
                                // size="sm"
                                className="px-1 py-0 text-center text-xs rounded-0"
                                style={{ height: "22px" }}
                                // onChange={onChange}
                                onChange={(e) => {
                                  let newCheckoutData = {
                                    ...checkoutData,
                                    additonal_discount: {
                                      ...checkoutData?.additonal_discount,
                                      value: e.target.value,
                                    },
                                  };
                                  dispatch(updateCheckoutData(newCheckoutData));
                                }}
                              />
                              <Button
                                size="sm"
                                variant={`${
                                  checkoutData?.additonal_discount?.type ===
                                  getCurrencySymbolText()
                                    ? "primary"
                                    : "transparent"
                                }`}
                                className="py-0 px-2 text-xs rounded-0"
                                onClick={() => {
                                  let newCheckoutData = {
                                    ...checkoutData,
                                    additonal_discount: {
                                      ...checkoutData?.additonal_discount,
                                      type: getCurrencySymbolText(),
                                    },
                                  };
                                  dispatch(updateCheckoutData(newCheckoutData));
                                }}
                              >
                                {/* value={getCurrencySymbolTextForDropdown()} */}
                                {getCurrencySymbolText()}
                              </Button>
                              <Button
                                size="sm"
                                variant={`${
                                  checkoutData?.additonal_discount?.type === "%"
                                    ? "primary"
                                    : "transparent"
                                }`}
                                className="py-0 px-2 text-xs rounded-0"
                                onClick={() => {
                                  let newCheckoutData = {
                                    ...checkoutData,
                                    additonal_discount: {
                                      ...checkoutData?.additonal_discount,
                                      type: "%",
                                    },
                                  };
                                  dispatch(updateCheckoutData(newCheckoutData));
                                }}
                              >
                                %
                              </Button>
                            </InputGroup>
                          </div>
                        </div>
                        <div className="ms-auto">
                          <p className="text-sm text-dark">
                            {getCurrencySymbolTextforTd(
                              calculatePrice().discountedPrice.toFixed(2)
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="hstack">
                        <div>
                          <p className="text-sm">Amount Payable</p>
                        </div>
                        <div className="ms-auto">
                          <p className="text-sm text-dark">
                            {" "}
                            {getCurrencySymbolTextforTd(
                              calculatePrice().finalPrice.toFixed(2)
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="hstack">
                        <div className="align-self-start">
                          <p className="text-sm">Amount Paid</p>
                        </div>
                        <div className="ms-auto">
                          {/* <p className="text-sm text-dark">₹3240</p> */}

                          <AmountInputForm
                            selectedClient={checkoutData?.clientData}
                            paymentEntries={paymentEntries}
                            setPaymentEntries={setPaymentEntries}
                            payableAmount={calculatePrice().finalPrice}
                            error={error}
                            setError={setError}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                </div>
                <div className="payment-section">
                  <div className="hstack border-top py-4 px-2">
                    <div>
                      Amount due:{" "}
                      {getCurrencySymbolTextforTd(
                        calculatePrice().amountDue.toFixed(2)
                      )}{" "}
                    </div>
                    <div className="ms-auto">
                      <Button
                        variant="secondary"
                        size="sm"
                        className="w-full mb-2 px-16"
                        // onClick={() => setLgShow(true)}
                        // disabled={disableGenerateInvoiceButton}
                        onClick={() => handleGenerateinvoice()}
                      >
                        Checkout
                      </Button>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>

          {/* <div className="d-flex">
            <div className="w-1/2 border"></div>
            <div className="w-1/2 border"></div>
          </div> */}
          <SelectClient
            setShowOffcanvasSelectClient={setShowOffcanvasSelectClient}
            showOffcanvasSelectClient={showOffcanvasSelectClient}
            setSelectedClient={(SelectedClient) => {
              let newCheckoutData = {
                ...checkoutData,
                clientData: SelectedClient,
              };
              dispatch(updateCheckoutData(newCheckoutData));
            }}
          />
          <AddNoteModel
            show={showAddNoteModel}
            onClose={() => setShowAddNoteModel(false)}
          />
        </PermissionGuard>
      </CommonLayout>
    </div>
  );
};

export default EditCheckout;
