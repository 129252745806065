import { createSlice } from "@reduxjs/toolkit";
import { addVendor, deleteVendor, editVendor, fetchVendors } from "./index";

const vendorSlice = createSlice({
  name: 'vendors',
  initialState: {
    vendors: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVendors.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVendors.fulfilled, (state, action) => {
        state.loading = false;
        state.vendors = action.payload;
      })
      .addCase(fetchVendors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addVendor.fulfilled, (state, action) => {
        state.vendors.push(action.payload);
      })
      .addCase(editVendor.fulfilled, (state, action) => {
        const index = state.vendors.findIndex((vendor) => vendor.id === action.payload.id);
        if (index !== -1) state.vendors[index] = action.payload;
      })
      .addCase(deleteVendor.fulfilled, (state, action) => {
        state.vendors = state.vendors.filter((vendor) => vendor.id !== action.payload);
      });
  },
});

export default vendorSlice.reducer;
