// Redux slice for managing tabbed invoice listing
import { createSlice } from "@reduxjs/toolkit";
import { fetchRecordLists } from ".";

const recordsSlice = createSlice({
  name: "records",
  initialState: {
    invoiceList: [],
    filterList: [], // This will contain options for filters
    currentPage: 1,
    perPage: 10,
    totalInvoices: 0,
    last_page: 1,
    loading: false,
    // filters: {}, // Example: { paymentMode: [], dates: [], staff: [] }
    filters: [], // Example: { paymentMode: [], dates: [], staff: [] }
    search: "",
    type: "all_invoices",
    delete_checkout_id: "",
  },
  reducers: {
    // setFilters: (state, action) => {
    //   const { key, values } = action.payload;
    //   state.filters[key] = values; // Dynamically update the filter category
    // },
    setFilters: (state, action) => {
      state.filterList = action.payload;
    },
    updateFilterSelection: (state, action) => {
      const { filterKey, value, isSelected, startDate, endDate } =
        action.payload;
      const filter = state.filterList.find(
        (f) => f.filter_key.value === filterKey
      );
      if (filter) {
        filter.filter_value.forEach((item) => {
          if (item.value === value) {
            item.isSelected = isSelected;
            if (startDate) item.startDate = startDate;
            if (endDate) item.endDate = endDate;
          }
        });
      }
    },

    clearFilters: (state) => {
      // state.filters = {}; // Clear all filters
      state.filterList = []; // Clear all filters
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setDeleteCheckoutId: (state, action) => {
      state.delete_checkout_id = action.payload;
    },
    clearDeleteCheckoutId: (state, action) => {
      state.delete_checkout_id = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecordLists.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRecordLists.fulfilled, (state, action) => {
        state.loading = false;
        state.invoiceList = action.payload.data.invoiceList; // Assuming the data array is in `data`
        state.totalInvoices = action.payload.data.total_invoices; // Assuming total is in `total`
        state.last_page = action.payload.data.last_page; // Lastpage of invoices
        // state.filterList = action.payload.filters; // Updated filters
        state.filterList = action.payload.data.filterList; // Updated filters
        state.delete_checkout_id = "";
      })
      .addCase(fetchRecordLists.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  setFilters,
  updateFilterSelection,
  clearFilters,
  setPage,
  setSearch,
  setType,
  setDeleteCheckoutId,
  clearDeleteCheckoutId,
} = recordsSlice.actions;

export default recordsSlice.reducer;
