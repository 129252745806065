import React, { useEffect, useState } from "react";
import CommonLayout from "../../component/commonLayout";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
  Stack,
  Table,
} from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { TbRefresh } from "react-icons/tb";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  convertTimeToUTC,
  convertUTCToUserTimeZone,
  formatDateFromISO,
  getCurrencySymbolText,
  getCurrencySymbolTextforTd,
  ShowTotalEntriesCount,
} from "../../utils/commonFunctions";
import moment from "moment";
import PageLoader from "../../component/commonLayout/PageLoader";
import { RiDeleteBin5Line, RiPencilLine } from "react-icons/ri";
import {
  fetchAllExpenseCategories,
  fetchAllExpenses,
} from "../../redux/expense";
import EditExpense from "../../component/OffCanvases/EditExpense";
import DeleteExpenseModel from "../../component/Models/DeleteExpenseModel";
import PermissionGuard from "../../component/commonLayout/PermissionGuard";
import { hasEditPermission } from "../../utils/validation";
import { fetchVendors } from "../../redux/vendor";
import DeleteVendorModel from "../../component/Models/DeleteVendorModel";
import EditVendor from "../../component/OffCanvases/EditVendor";

const VendorList = () => {
  const dispatch = useDispatch();
  const [showOffcanvas1, setShowOffcanvas1] = useState(false);
  const [selectedVendorEntry, setSelectedVendorEntry] = useState({});
  const [selectVendor, setSelectVendor] = useState({
    search: "",
  });
  const allVendors = useSelector((state) => state?.vendors?.vendors);
  const loading = useSelector((state) => state?.vendors?.loading);

  useEffect(() => {
    if (allVendors && isObjectEmpty(allVendors)) {
      dispatch(fetchVendors({}));
    }
  }, [allVendors, dispatch]);

  function isObjectEmpty(data) {
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
  // Function to toggle the visibility of Offcanvas 1 - Edit Expense
  const toggleOffcanvas1 = (item) => {
    setSelectedVendorEntry(item);
    setShowOffcanvas1(!showOffcanvas1);
  };

  const [deleteModelShow, setDeleteModelShow] = useState(false);

  // Function to toggle the visibility of Model - Delete Expense
  const toggleDeleteModel = (item) => {
    setSelectedVendorEntry(item);
    setDeleteModelShow(!showOffcanvas1);
  };

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setSelectVendor((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // dispatch(
    //   fetchAllExpenses({
    //     ...selectVendor,
    //     start_date: convertTimeToUTC(moment(selectVendor.start_date).format("DD-MM-YYYY"), moment().format("hh:mm A")),
    //     end_date: convertTimeToUTC(moment(selectVendor.end_date).format("DD-MM-YYYY"), moment().format("hh:mm A")),
    //     [name]: value,
    //   })
    // );
    dispatch(
      fetchVendors({
        ...selectVendor,
        [name]: value,
      })
    );
  };

  const handleDateChange = (update) => {
    setDateRange(update);
    const [startDate, endDate] = update;
    const formattedStartDate = startDate
      ? moment(startDate).format("DD-MM-YYYY")
      : null;
    const formattedEndDate = endDate
      ? moment(endDate).format("DD-MM-YYYY")
      : null;
    setSelectVendor((prevState) => ({
      ...prevState,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
    }));

    dispatch(
      fetchVendors({
        ...selectVendor,
        // start_date: formattedStartDate,
        // end_date: formattedEndDate,
        start_date: convertTimeToUTC(
          moment(formattedStartDate).format("DD-MM-YYYY"),
          moment().format("hh:mm A")
        ),
        end_date: convertTimeToUTC(
          moment(formattedEndDate).format("DD-MM-YYYY"),
          moment().format("hh:mm A")
        ),
      })
    );
  };

  const refresh = () => {
    // dispatch(
    //   fetchVendors({
    //     search: selectVendor.search,
    //     // start_date: selectVendor.start_date,
    //     // end_date: selectVendor.end_date,
    //     start_date: convertTimeToUTC(moment(selectVendor.start_date).format("DD-MM-YYYY"), moment().format("hh:mm A")),
    //     end_date: convertTimeToUTC(moment(selectVendor.end_date).format("DD-MM-YYYY"), moment().format("hh:mm A")),
    //     payment_mode: selectVendor.payment_mode,
    //     expense_type: selectVendor.expense_type,
    //   })
    // );
    dispatch(
      fetchVendors({
        ...selectVendor,
      })
    );
  };
  const modulesPermissions = useSelector(
    (state) => state?.authSession?.modulesPermissions
  );
  return (
    <PermissionGuard
      hasPermission={modulesPermissions?.vendor?.is_view || true}
    >
      {" "}
      <div>
        {loading && <PageLoader />}
        <CommonLayout>
          <Stack direction="horizontal" className="gap-3 mb-6">
            <div className="flex-fill">
              <h4 className="mb-2">Vendors List</h4>
              <p>You can see your added vendors here</p>
            </div>

            <div className="hstack gap-3 ">
              <div className="datepicker-staff d-none">
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  name="dateRange"
                  placeholderText="Select a date Range"
                  onChange={handleDateChange}
                  isClearable={true}
                  allowSameDay
                  peekNextMonth={false}
                  dateFormat={"MMM dd, YYYY"}
                  autoComplete="off"
                />
              </div>
              <Button
                variant=""
                size="md"
                className="rounded-1 border text-primary"
                onClick={refresh}
              >
                <TbRefresh /> Refresh
              </Button>
              {/* <Button
              variant=""
              size="md"
              className="rounded-1 border text-primary"
              onClick={refresh}
            >
              <TbRefresh /> clear
            </Button> */}
            </div>
          </Stack>

          <div className="mt-8">
            <Card className="rounded-bottom-0">
              <Card.Body>
                <Row className="align-items-center gy-4 justify-content-between">
                  <Col lg={4}>
                    <Stack direction="horizontal" className="gap-2">
                      <h6 className="text-nowrap">
                        {ShowTotalEntriesCount(
                          allVendors?.length,
                          "Vendor",
                          "Vendors"
                        )}{" "}
                      </h6>
                      <InputGroup className="form-group-1 w-sm-72 rounded-pill border me-auto d-none">
                        <InputGroup.Text className="border-0 rounded-pill bg-transparent pe-1 ps-3">
                          <BiSearch className="text-lg" />
                        </InputGroup.Text>
                        <FormControl
                          className="border-0 rounded-pill ps-1"
                          type="text"
                          name="search"
                          placeholder="Search expense"
                          value={selectVendor?.search}
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </Stack>
                  </Col>
                  <Col lg={4} className="d-none">
                    <Stack direction="horizontal" className="gap-2">
                      <Form.Select
                        aria-label="Default select example "
                        size="sm"
                        name="expense_type"
                        value={selectVendor.expense_type}
                        onChange={handleInputChange}
                      >
                        {/* <option value="">All categories</option> */}
                        {/* {expenseCategoriesList?.map((category, categoryIndex)=><option value={category}>{category}</option>)} */}
                        <option value="">Select Expense type</option>
                        <option value="One-time">One Time</option>
                        <option value="Recurring">Recurring</option>
                      </Form.Select>
                      <Form.Select
                        aria-label="Default select example "
                        size="sm"
                        name="payment_mode"
                        value={selectVendor.payment_mode}
                        onChange={handleInputChange}
                      >
                        {/* <option value="">All</option> */}
                        <option value="">Select Payment Mode</option>
                        <option value="Cash">Cash</option>
                        <option value="Online">Online</option>
                      </Form.Select>
                    </Stack>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <div className="table-responsive border-start border-end mb-4">
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Number</th>
                    <th>Email</th>
                    <th>PAN Number</th>
                    <th>GSTIN</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allVendors?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item?.name}</td>
                        <td>{item?.mobile}</td>
                        <td>{item?.email}</td>
                        <td>{item?.panNumber}</td>
                        <td>{item?.gstin}</td>

                        <td>
                          <div className="d-flex justify-content-start gap-2">
                            <Button
                              variant=""
                              onClick={() => toggleOffcanvas1(item)}
                              className="bg-danger-light text-secondary p-0 w-5 h-5 d-flex align-items-center justify-content-center rounded-2"
                              disabled={
                                !hasEditPermission(
                                  modulesPermissions?.vendor?.is_edit || true
                                )
                              }
                            >
                              <RiPencilLine className="text-xs" />
                            </Button>
                            <Button
                              variant=""
                              onClick={() => {
                                toggleDeleteModel(item);
                              }}
                              disabled={
                                !hasEditPermission(
                                  modulesPermissions?.vendor?.is_delete || true
                                )
                              }
                              className="bg-danger-light text-secondary p-0 w-5 h-5 d-flex align-items-center justify-content-center rounded-2"
                            >
                              <RiDeleteBin5Line />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>

          <EditVendor
            showOffcanvas1={showOffcanvas1}
            setShowOffcanvas1={setShowOffcanvas1}
            selectedVendorEntry={selectedVendorEntry}
            refresh={refresh}
          />
          <DeleteVendorModel
            deleteModelShow={deleteModelShow}
            setDeleteModelShow={setDeleteModelShow}
            selectedVendorEntry={selectedVendorEntry}
            refresh={refresh}
          />
        </CommonLayout>
      </div>{" "}
    </PermissionGuard>
  );
};

export default VendorList;
