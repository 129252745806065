// Redux slice for managing tabbed invoice listing
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { isObjectEmpty } from "../../utils/commonFunctions";
import API from "../../apis/api";

export const fetchRecordLists = createAsyncThunk(
  "records/fetchRecordLists",
  async (params, { getState, rejectWithValue }) => {
    const state = getState();
    const {
      perPage,
      currentPage,
      filters,
      search,
      type,
      filterList,
      delete_checkout_id,
    } = state.records;

    try {
      const formData = new FormData();
      // console.log(params);

      // for (const key in params) {
      //   if (key === "filters" && isObjectEmpty(params[key])) {
      //     formData.append(key, JSON.stringify([]));
      //     // formData.append('filter', JSON.stringify([]));
      //     console.log("empty");

      //   } else {
      //     formData.append(key, JSON.stringify(params[key]));
      //     console.log("not-empty", params[key],JSON.stringify(params[key]) );
      //   }
      // }
      // Attach filters dynamically
      formData.append("filter", JSON.stringify(filterList));
      formData.append("type", type);
      formData.append("delete_checkout_id", delete_checkout_id);
      console.log(formData.values());

      const backend = new API();
      const response = await backend.fetch(
        API.getRecordLists({
          perPage: perPage,
          page: currentPage,
          search: search,
        }),
        {
          method: "POST",
          body: formData,
        }
      );

      // return response.data;
      return response;
    } catch (error) {
      console.log(error);

      return rejectWithValue(error.response.data);
    }
  }
);
