import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../apis/api";
import { loadingEnd, loadingStart } from "../setLoading/setLoading";
import ToastMessage from "../../component/toast/ToastMessage";
import { inventoryLoadingEnd, inventoryLoadingStart } from "./inventorySlice";

// Async Thunk for Fetching Product List
export const fetchProductList = createAsyncThunk(
  "inventory/fetchProductList",
  async ({ tab, search, category, page }, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    dispatch(inventoryLoadingStart());
    const backend = new API();
    try {
      // const response = await API.fetch(`/products`, {
      //   params: { type: tab, search, category, page },
      //   method: 'GET',
      // });
      const response = await backend.fetch(
        API.getInventoryProductList({ type: tab, search, category, page }),
        {
          method: "GET",
        }
      );
      dispatch(loadingEnd());
      dispatch(inventoryLoadingEnd());
      // ToastMessage(response);
      return { tab, data: response };
    } catch (error) {
      dispatch(loadingEnd());
      dispatch(inventoryLoadingEnd());
      console.error(`Error fetching ${tab} products:`, error);
      return rejectWithValue(error.message);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "inventory/deleteProduct",
  async (productId, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    dispatch(inventoryLoadingStart());
    const backend = new API();
    try {
      const response = await backend.fetch(API.delete_product(productId), {
        method: "DELETE",
      });
      dispatch(loadingEnd());
      dispatch(inventoryLoadingEnd());
      ToastMessage(response);
      return productId;
    } catch (error) {
      dispatch(loadingEnd());
      dispatch(inventoryLoadingEnd());
      console.error(`Error deleting product:`, error);
      return rejectWithValue(error.message);
    }
  }
);

export const addStock = createAsyncThunk(
  "inventory/addStock",
  async ({ id, data }, { rejectWithValue, dispatch }) => {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    try {
      const backend = new API();
      const response = await backend.fetch(API.addStock(id), {
        method: "POST",
        body: formData,
      });
      dispatch(loadingEnd());
      // dispatch(inventoryLoadingEnd());
      ToastMessage(response);
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      // dispatch(inventoryLoadingEnd());
      console.error(`Error adding stock:`, error);
      return rejectWithValue(error.message);
    }
  }
);

export const reduceStock = createAsyncThunk(
  "inventory/reduceStock",
  async ({ id, data }, { rejectWithValue, dispatch }) => {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    try {
      const backend = new API();
      const response = await backend.fetch(API.reduceStock(id), {
        method: "POST",
        body: formData,
      });
      dispatch(loadingEnd());
      // dispatch(inventoryLoadingEnd());
      ToastMessage(response);
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      // dispatch(inventoryLoadingEnd());
      console.error(`Error reducing stock:`, error);
      return rejectWithValue(error.message);
    }
  }
);

export const editProduct = createAsyncThunk(
  "inventory/editProduct",
  async ({ id, data }, { rejectWithValue, dispatch }) => {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    try {
      const backend = new API();
      const response = await backend.fetch(API.editInventoryProduct(id), {
        method: "POST",
        body: formData,
      });
      dispatch(loadingEnd());
      // dispatch(inventoryLoadingEnd());
      ToastMessage(response);
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      // dispatch(inventoryLoadingEnd());
      console.error(`Error reducing stock:`, error);
      return rejectWithValue(error.message);
    }
  }
);

export const fetchCatagories = createAsyncThunk(
  "inventory/fetchCatagories",
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    dispatch(inventoryLoadingStart());
    const backend = new API();
    try {
      const response = await backend.fetch(API.get_product_categories(), {
        method: "GET",
      });
      dispatch(loadingEnd());
      // dispatch(inventoryLoadingEnd());
      ToastMessage(response);
      return response?.data;
    } catch (error) {
      dispatch(loadingEnd());
      // dispatch(inventoryLoadingEnd());
      console.error(`Error fetching product categories:`, error);
      return rejectWithValue(error.message);
    }
  }
);

export const addProductCategories = createAsyncThunk(
  "inventory/addProductCategories",
  async (data, { rejectWithValue, dispatch }) => {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    try {
      const backend = new API();
      const response = await backend.fetch(API.add_product_categories(), {
        method: "POST",
        body: formData,
      });
      dispatch(loadingEnd());
      // dispatch(inventoryLoadingEnd());
      ToastMessage(response);
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      // dispatch(inventoryLoadingEnd());
      console.error(`Error adding category:`, error);
      return rejectWithValue(error.message);
    }
  }
);

export const addProduct = createAsyncThunk(
  "inventory/addProduct",
  async (data, { rejectWithValue, dispatch }) => {
    const formData = new FormData();
    for (const key in data) {
      if (key === "images") {
        if (typeof data[key] === "string") {
          // Convert URL to File
          const response = await fetch(data[key]);
          // const response = await fetch('https://dev.tapgro.com/assets/images/product_image.png');
          const blob = await response.blob();
          const file = new File([blob], "existing-image.jpg", {
            type: blob.type,
          });
          formData.append(key, file);
        } else {
          // Add the new image file
          formData.append(key, data[key]);
        }
      } else {
        formData.append(key, data[key]);
      }
    }
    try {
      const backend = new API();
      const response = await backend.fetch(API.addInventoryProduct(), {
        method: "POST",
        body: formData,
      });
      dispatch(loadingEnd());
      // dispatch(inventoryLoadingEnd());
      ToastMessage(response);
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      // dispatch(inventoryLoadingEnd());
      console.error(`Error adding category:`, error);
      return rejectWithValue(error.message);
    }
  }
);

export const fetchMasterProduct = createAsyncThunk(
  "inventory/fetchMasterProduct",
  async ({ barcode }, { rejectWithValue, dispatch }) => {
    // dispatch(loadingStart());
    dispatch(inventoryLoadingStart());
    const backend = new API();
    try {
      const response = await backend.fetch(API.getMasterProduct({ barcode }), {
        method: "GET",
      });
      // dispatch(loadingEnd());
      dispatch(inventoryLoadingEnd());
      ToastMessage(response);
      return response;
    } catch (error) {
      // dispatch(loadingEnd());
      dispatch(inventoryLoadingEnd());
      console.error(`Error fetching products:`, error);
      return rejectWithValue(error.message);
    }
  }
);
