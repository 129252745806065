// Redux slice for managing tabbed invoice listing
import { createSlice } from "@reduxjs/toolkit";
import { fetchCheckoutDetails, fetchCheckoutItemLists } from ".";

const checkoutSlice = createSlice({
  name: "checkout",
  initialState: {
    checkoutItems: {},
    mainCategories: [],
    categories:[],
    salesData: [],
    checkoutData: {date: new Date(),clientData : {}, additonal_discount :{value : "0", type: "%"}, payment_mode:[{id: Date.now(), price: '', paymentMode: ''}], notes:'' ,selected_taxes:'18'},
    checkoutDetailes: {},
    loading: false,
  },
  reducers: {
 
    setCheckoutItems: (state, action) => {
      state.checkoutItems = action.payload;
    },
    updateFilterSelection: (state, action) => {
      const { filterKey, value, isSelected, startDate, endDate } =
        action.payload;
      const filter = state.filterList.find(
        (f) => f.filter_key.value === filterKey
      );
      if (filter) {
        filter.filter_value.forEach((item) => {
          if (item.value === value) {
            item.isSelected = isSelected;
            if (startDate) item.startDate = startDate;
            if (endDate) item.endDate = endDate;
          }
        });
      }
    },
    updateSalesData: (state, action) => {
      // state.checkoutItems = action.payload;
      state.salesData = action.payload;
      let newCheckoutItems = {...state.checkoutItems, ...action.payload };
      state.checkoutItems = newCheckoutItems;
    },
    updateCheckoutData: (state, action) => {
      let newCheckoutData = {...state.checkoutData, ...action.payload };
      state.checkoutData = newCheckoutData;
    },
    clearCheckoutItems: (state) => {
      state.checkoutItems = {}; // Clear all items
    },
    clearCheckoutData: (state) => {
      state.checkoutData = {date: new Date(),clientData : {}, additonal_discount :{value : "0", type: "%"}, payment_mode:[{id: Date.now(), price: '', paymentMode: 'Cash'}], notes:'' ,selected_taxes:'18'}; // Reset all data
    },
    clearCheckoutDetailes: (state) => {
      state.checkoutDetailes = {}; // Clear all items
    },
  
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCheckoutItemLists.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCheckoutItemLists.fulfilled, (state, action) => {
        state.loading = false;
        state.checkoutItems = action.payload.data; // Assuming the data is in `data`
        state.mainCategories = action.payload.data.mainCategories; // Assuming the data array is in `data`
        state.categories = action.payload.data.categories; 
        state.salesData = action.payload.data.salesData; 
        
      
      })
      .addCase(fetchCheckoutItemLists.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchCheckoutDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCheckoutDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.checkoutDetailes = action.payload.data; // Assuming the data is in `data`
      
        
      
      })
      .addCase(fetchCheckoutDetails.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  setCheckoutItems,
  updateFilterSelection,
  clearCheckoutItems,
  updateSalesData,updateCheckoutData,
  clearCheckoutData,
  clearCheckoutDetailes,
 
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
