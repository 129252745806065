import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Tab,
  Tabs,
  Table,
  Button,
  Pagination,
  Form,
  Spinner,
  Dropdown,
  Tooltip,
  OverlayTrigger,
  Modal,
  InputGroup,
  Image,
  Card,
  ButtonGroup,
  FormControl,
  Row,
  Col,
} from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  convertUTCToUserTimeZone,
  getClassNameForClientTag,
  getCurrencySymbolTextforTd,
  ShowTotalEntriesCount,
} from "../../utils/commonFunctions";
import { fetchRecordLists } from "../../redux/salesNewListing";
import {
  clearDeleteCheckoutId,
  clearFilters,
  setDeleteCheckoutId,
  setFilters,
  setPage,
  setSearch,
  setType,
  updateFilterSelection,
} from "../../redux/salesNewListing/recordsSlice";
import moment from "moment";
import { toast } from "react-toastify";
import PermissionGuard from "../../component/commonLayout/PermissionGuard";
import PageLoader from "../../component/commonLayout/PageLoader";
import CommonLayout from "../../component/commonLayout";
import { TbRefresh } from "react-icons/tb";
import { HiEye, HiOutlineCog6Tooth, HiPencil, HiTrash } from "react-icons/hi2";
import { RiResetLeftFill } from "react-icons/ri";
import { FaAngleDown, FaPlus } from "react-icons/fa";
import { EmptyBox } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import { hasEditPermission } from "../../utils/validation";
import { MdClear, MdInventory } from "react-icons/md";
import RecordInventoryModel from "../../component/Models/RecordInventoryModel";
import { FiArrowRight } from "react-icons/fi";
// import { fetchRecordLists, setFilters, setPage, setSearch, setType } from './recordSlice';
// import convertUTCToUserTimeZone from './timeZoneUtils';

const TabbedInvoicePage = () => {
  const dispatch = useDispatch();
  const {
    invoiceList,
    filterList,
    currentPage,
    perPage,
    totalInvoices,
    last_page,
    loading,
    filters,
    search,
    type,
  } = useSelector((state) => state.records);
  console.log({
    invoiceList,
    filterList,
    currentPage,
    perPage,
    totalInvoices,
    loading,
    filters,
    search,
    type,
  });

  const [activeTab, setActiveTab] = useState("all_invoices");
  const [localFilters, setLocalFilters] = useState([]);

  useEffect(() => {
    dispatch(
      fetchRecordLists({
        perPage,
        page: currentPage,
        search,
        filter: localFilters,
        type: activeTab,
      })
    );
  }, [dispatch, activeTab, currentPage, perPage, search, localFilters]);

  const handleFilterChange = (filterKey, optionValue) => {
    setLocalFilters((prevFilters) => {
      const updatedFilters = [...prevFilters];
      const filterIndex = updatedFilters.findIndex(
        (f) => f.filter_key.value === filterKey
      );

      if (filterIndex > -1) {
        const selectedValues = updatedFilters[filterIndex].filter_value;
        const optionIndex = selectedValues.findIndex(
          (opt) => opt.value === optionValue
        );

        if (optionIndex > -1) {
          selectedValues.splice(optionIndex, 1);
        } else {
          selectedValues.push({ value: optionValue, isSelected: true });
        }
      } else {
        updatedFilters.push({
          filter_key: { value: filterKey },
          filter_value: [{ value: optionValue, isSelected: true }],
        });
      }

      return updatedFilters;
    });
  };

  const handleDateChange = (filterKey, startDate, endDate) => {
    setLocalFilters((prevFilters) => {
      const updatedFilters = [...prevFilters];
      const filterIndex = updatedFilters.findIndex(
        (f) => f.filter_key.value === filterKey
      );

      if (filterIndex > -1) {
        updatedFilters[filterIndex].filter_value = [
          { value: "custom", isSelected: true, startDate, endDate },
        ];
      } else {
        updatedFilters.push({
          filter_key: { value: filterKey },
          filter_value: [
            { value: "custom", isSelected: true, startDate, endDate },
          ],
        });
      }

      return updatedFilters;
    });
  };

  const handleRefresh = () => {
    setLocalFilters([]);
    dispatch(
      fetchRecordLists({
        perPage,
        page: 1,
        search: "",
        filter: [],
        type: activeTab,
      })
    );
  };

  const handlePageChange = (page) => {
    dispatch(setPage(page));
  };
  const modulesPermissions = useSelector(
    (state) => state?.authSession?.modulesPermissions
  );

  return (
    <PermissionGuard
      hasPermission={modulesPermissions?.billing?.sales?.is_view}
    >
      <div>
        {loading && <PageLoader />}
        <CommonLayout>
          <div className="hstack gap-1 mb-6">
            <div className="flex-fill">
              <h4 className="mb-2">Sale entries</h4>
              <p>View, filter and export the history of your sales</p>
            </div>
            <Button
              variant=""
              size="sm"
              className="rounded-1 border text-primary"
              onClick={handleRefresh}
            >
              <TbRefresh /> Refresh
            </Button>
          </div>
          <Tabs
            activeKey={activeTab}
            onSelect={(key) => {
              setActiveTab(key);
              setLocalFilters([]);
              dispatch(setPage(1));
              dispatch(setType(key));
            }}
            id="noanim-tab-example"
            className="mb-10 mt-8 tabs-type-1"
          >
            <Tab eventKey="all_invoices" title="All Invoices">
              <Filters
                filterList={filterList}
                onFilterChange={handleFilterChange}
                onDateChange={handleDateChange}
              />
              <InvoiceTable
                loading={loading}
                invoiceList={invoiceList}
                totalInvoices={totalInvoices}
                currentPage={currentPage}
                last_page={last_page}
                perPage={perPage}
                onPageChange={handlePageChange}
                invoiceType={"all_invoices"}
              />
            </Tab>

            <Tab eventKey="draft_invoices" title="Draft Invoices">
              <Filters
                filterList={filterList}
                onFilterChange={handleFilterChange}
                onDateChange={handleDateChange}
              />
              <InvoiceTable
                loading={loading}
                invoiceList={invoiceList}
                totalInvoices={totalInvoices}
                currentPage={currentPage}
                last_page={last_page}
                perPage={perPage}
                onPageChange={handlePageChange}
                invoiceType={"draft_invoices"}
              />
            </Tab>
          </Tabs>
        </CommonLayout>
      </div>
    </PermissionGuard>
  );
};

// const Filters = () => {
//   const dispatch = useDispatch();
//   const { filterList, filters } = useSelector((state) => state.records);
//   const [localFilters, setLocalFilters] = useState(filters);
//   const [dateRange, setDateRange] = useState([null, null]);
//   const [showDatePicker, setShowDatePicker] = useState(false);

//   useEffect(() => {
//     // Trigger initial API call to fetch filter configuration and data
//     dispatch(fetchRecordLists());
//   }, [dispatch]);

//   const handleFilterChange = (key, selectedOption) => {
//     if (key === "dates" && selectedOption.value === "Custom") {
//       setShowDatePicker(true); // Show date range picker for custom
//       return;
//     }

//     setLocalFilters((prev) => ({
//       ...prev,
//       [key]: selectedOption.value ? [selectedOption.value] : [],
//     }));
//     dispatch(setFilters({ key, values: [selectedOption.value] }));
//   };

//   const handleApplyCustomDate = () => {
//     setShowDatePicker(false);
//     setLocalFilters((prev) => ({
//       ...prev,
//       dates: [`${dateRange[0]?.toISOString()} - ${dateRange[1]?.toISOString()}`],
//     }));
//     dispatch(
//       setFilters({
//         key: "dates",
//         values: [`${dateRange[0]?.toISOString()} - ${dateRange[1]?.toISOString()}`],
//       })
//     );
//   };

//   const handleClearAll = () => {
//     setLocalFilters({});
//     setDateRange([null, null]);
//     setShowDatePicker(false);
//     dispatch(clearFilters());
//   };

//   return (
//     <div className="filter-container">
//       {filterList?.map((filter) => (
//         <div key={filter?.key} className="filter-dropdown">
//           <label>{filter?.label}</label>
//           {filter?.key === "dates" ? (
//             <>
//               <Select
//                 options={filter?.options?.map((value) => ({
//                   label: value.label,
//                   value: value.value,
//                 }))}
//                 value={
//                   localFilters[filter?.key]?.[0]
//                     ? {
//                         label: localFilters[filter?.key][0],
//                         value: localFilters[filter?.key][0],
//                       }
//                     : null
//                 }
//                 onChange={(selectedOption) =>
//                   handleFilterChange(filter?.key, selectedOption)
//                 }
//               />
//               {showDatePicker && (
//                 <div className="date-range-picker">
//                   <DatePicker
//                     selectsRange
//                     startDate={dateRange[0]}
//                     endDate={dateRange[1]}
//                     onChange={(update) => setDateRange(update)}
//                     isClearable
//                   />
//                   <button onClick={handleApplyCustomDate}>Apply Date Range</button>
//                 </div>
//               )}
//             </>
//           ) : (
//             <Select
//               isMulti={filter?.key !== "dates"}
//               options={filter?.options?.map((value) => ({
//                 label: value?.label,
//                 value: value?.value,
//               }))}
//               value={
//                 localFilters[filter?.key]?.map((value) => ({
//                   label: value,
//                   value,
//                 })) || []
//               }
//               onChange={(selectedOptions) =>
//                 handleFilterChange(
//                   filter?.key,
//                   selectedOptions ? selectedOptions : []
//                 )
//               }
//             />
//           )}
//         </div>
//       ))}

//       <div className="filter-actions">
//         <button className="btn btn-secondary" onClick={handleClearAll}>
//           Clear All
//         </button>
//         <button
//           className="btn btn-primary"
//           onClick={() => {
//             dispatch(fetchRecordLists()); // Fetch records with updated filters
//           }}
//         >
//           Apply Filters
//         </button>
//       </div>
//     </div>
//   );
// };

const Filters = () => {
  const dispatch = useDispatch();
  const filterList = useSelector((state) => state.records.filterList);
  const [updateUI, setUpdateUI] = useState(0);
  const handleFilterChange = (filterKey, value, isSelected) => {
    dispatch(updateFilterSelection({ filterKey, value, isSelected }));
    // setUpdateUI(updateUI + 1);
  };
  useEffect(() => {
    setUpdateUI(updateUI + 1);
  }, [filterList]);

  const handleDateRangeChange = (filterKey, startDate, endDate) => {
    const formattedStartDate = startDate
      ? moment(startDate).format("YYYY-MM-DD")
      : null;
    const formattedEndDate = endDate
      ? moment(endDate).format("YYYY-MM-DD")
      : null;
    dispatch(
      updateFilterSelection({
        filterKey,
        value: "custom",
        isSelected: true,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      })
    );
  };

  const handleClearAll = () => {
    // setLocalFilters({});
    // setDateRange([null, null]);
    // setShowDatePicker(false);
    dispatch(clearFilters());

    dispatch(fetchRecordLists());
  };

  //// // Serach flow //// //
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState("");
  // Debouncing logic for search
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 500); // Debounce delay of 500ms

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  // Fetch records when debounced search text changes
  useEffect(() => {
    dispatch(setSearch(debouncedSearchText));

    dispatch(fetchRecordLists());
  }, [debouncedSearchText, dispatch]);
  return (
    <Card className="rounded-bottom-0">
      <Card.Body>
        <div className=" d-flex align-items-top gy-4 justify-content-between">
          <div lg={7}>
            {/* Filter Section (Placeholder for filters) */}
            <div className="d-flex justify-content-between gap-2">
              {filterList.map((filter) => (
                <div key={filter.filter_key.value} style={{ widthx: "300px" }}>
                  <Dropdown
                    // as={ButtonGroup}
                    className="bg-light dropdown-toggle-remove w-full position-static"
                    autoClose="outside"
                  >
                    <Dropdown.Toggle
                      id="dropdown-autoclose-outside"
                      // className="bg-light border-0 p-0 w-full"
                      className="bg-light border-0 p-0 w-full"
                    >
                      <InputGroup className="form-group-1 border me-auto w-full flex-nowrap">
                        <FormControl
                          className="border-0 flex-grow-1"
                          // style={{ minWidth: "120px" }} // Ensuring a minimum width
                          type="text"
                          name="search"
                          // value={showStaffName(item?.stylist)}
                          value={filter.filter_key.label}
                          placeholder={filter.filter_key.label}
                          autoComplete="off"
                          readOnly
                        />
                        <InputGroup.Text className="border-0  bg-transparent pe-1 ps-3">
                          <FaAngleDown />
                        </InputGroup.Text>
                      </InputGroup>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="">
                      {filter.filter_value.map((option) => (
                        <Dropdown.Item key={option.value}>
                          {option.value === "custom" ? (
                            <>
                              <Form.Check type="radio">
                                <Form.Check.Input
                                  type="radio"
                                  checked={option.isSelected}
                                  onChange={(e) =>
                                    handleFilterChange(
                                      filter.filter_key.value,
                                      option.value,
                                      e.target.checked
                                    )
                                  }
                                />
                                <Form.Check.Label className="ms-2 text-wrap">
                                  {option.label}
                                </Form.Check.Label>
                              </Form.Check>
                              {/* <label>
                          <input
                            type="radio"
                            checked={option.isSelected}
                            onChange={() =>
                              handleFilterChange(
                                filter.filter_key.value,
                                "custom",
                                true
                              )
                            }
                          />
                          {option.label}
                        </label> */}
                              {option.isSelected && (
                                <div>
                                  <DatePicker
                                    selected={
                                      option.startDate
                                        ? new Date(option.startDate)
                                        : null
                                    }
                                    onChange={(date) =>
                                      handleDateRangeChange(
                                        filter.filter_key.value,
                                        date,
                                        option.endDate
                                      )
                                    }
                                    selectsStart
                                    startDate={
                                      option.startDate
                                        ? new Date(option.startDate)
                                        : null
                                    }
                                    // startDate={new Date(option.startDate)}
                                    endDate={
                                      option.endDate
                                        ? new Date(option.endDate)
                                        : null
                                    }

                                    // dateFormat={"yyyy-MM-DD"}
                                  />
                                  <DatePicker
                                    selected={
                                      option.endDate
                                        ? new Date(option.endDate)
                                        : null
                                    }
                                    onChange={(date) =>
                                      handleDateRangeChange(
                                        filter.filter_key.value,
                                        option.startDate,
                                        date
                                      )
                                    }
                                    selectsEnd
                                    startDate={
                                      option.startDate
                                        ? new Date(option.startDate)
                                        : null
                                    }
                                    endDate={
                                      option.endDate
                                        ? new Date(option.endDate)
                                        : null
                                    }
                                    minDate={
                                      option.startDate
                                        ? new Date(option.startDate)
                                        : null
                                    }
                                  />
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              <Form.Check type="checkbox">
                                <Form.Check.Input
                                  type="checkbox"
                                  checked={option.isSelected}
                                  onChange={(e) =>
                                    handleFilterChange(
                                      filter.filter_key.value,
                                      option.value,
                                      e.target.checked
                                    )
                                  }
                                />
                                <Form.Check.Label className="ms-2 text-wrap">
                                  {option.label}
                                </Form.Check.Label>
                              </Form.Check>
                              {/* <label>
                          <input
                            type="checkbox"
                            checked={option.isSelected}
                            onChange={(e) =>
                              handleFilterChange(
                                filter.filter_key.value,
                                option.value,
                                e.target.checked
                              )
                            }
                            className="ms-2 text-wrap"
                          />
                          {option.label}
                        </label> */}
                            </>
                          )}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ))}

              {/* {filterList.map((filter) => (
          <div key={filter.filter_key.value}>
            <h4>{filter.filter_key.label}</h4>
            {filter.filter_value.map((option) => (
              <div key={option.value}>
                {option.value === "custom" ? (
                  <div>
                    <label>
                      <input
                        type="radio"
                        checked={option.isSelected}
                        onChange={() =>
                          handleFilterChange(
                            filter.filter_key.value,
                            "custom",
                            true
                          )
                        }
                      />
                      {option.label}
                    </label>
                    {option.isSelected && (
                      <div>
                        <DatePicker
                          selected={
                            option.startDate ? new Date(option.startDate) : null
                          }
                          onChange={(date) =>
                            handleDateRangeChange(
                              filter.filter_key.value,
                              date,
                              option.endDate
                            )
                          }
                          selectsStart
                          startDate={
                            option.startDate ? new Date(option.startDate) : null
                          }
                          // startDate={new Date(option.startDate)}
                          endDate={
                            option.endDate ? new Date(option.endDate) : null
                          }

                          // dateFormat={"yyyy-MM-DD"}
                        />
                        <DatePicker
                          selected={
                            option.endDate ? new Date(option.endDate) : null
                          }
                          onChange={(date) =>
                            handleDateRangeChange(
                              filter.filter_key.value,
                              option.startDate,
                              date
                            )
                          }
                          selectsEnd
                          startDate={
                            option.startDate ? new Date(option.startDate) : null
                          }
                          endDate={
                            option.endDate ? new Date(option.endDate) : null
                          }
                          minDate={
                            option.startDate ? new Date(option.startDate) : null
                          }
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <label>
                    <input
                      type="checkbox"
                      checked={option.isSelected}
                      onChange={(e) =>
                        handleFilterChange(
                          filter.filter_key.value,
                          option.value,
                          e.target.checked
                        )
                      }
                    />
                    {option.label}
                  </label>
                )}
              </div>
            ))}
          </div>
        ))} */}
            </div>
          </div>
          <div lg={3} className="ms-3">
            {/* Search Box */}
            <InputGroup style={{ width: "200px" }}>
              <Form.Control
                type="text"
                placeholder="Search by client name or invoice details"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </InputGroup>
          </div>
          <div lg={2}>
            <div className="filter-actions">
              <div className="d-flex align-items-center justify-content-end ms-auto">
                <Button
                  onClick={handleClearAll}
                  variant="secondary-outline"
                  className="ms-auto py-2 rounded-0 rounded-end"
                >
                  <MdClear />
                  {/* <FiArrowRight /> */}
                </Button>
                <Button
                  onClick={() => {
                    dispatch(fetchRecordLists()); // Fetch records with updated filters
                  }}
                  variant="primary"
                  className="ms-auto py-2 rounded-0 rounded-end"
                >
                  {/* <RiResetLeftFill /> */}
                  <FiArrowRight />
                </Button>
              </div>
              {/* <button className="btn btn-secondary" onClick={handleClearAll}>
            Clear All
          </button> */}
              {/* <button
            className="btn btn-primary"
            onClick={() => {
              dispatch(fetchRecordLists()); // Fetch records with updated filters
            }}
          >
            Apply Filters
          </button> */}
            </div>
          </div>
        </div>
      </Card.Body>
      <div className="d-flex justify-content-between align-items-center mb-0 d-none">
        {/* Search Box */}
        <InputGroup style={{ width: "300px" }}>
          <Form.Control
            type="text"
            placeholder="Search by client name or invoice details"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </InputGroup>

        {/* Filter Section (Placeholder for filters) */}
        <div>
          {filterList.map((filter) => (
            <div key={filter.filter_key.value}>
              <Dropdown
                // as={ButtonGroup}
                className="bg-light dropdown-toggle-remove w-full position-static"
                autoClose="outside"
              >
                <Dropdown.Toggle
                  id="dropdown-autoclose-outside"
                  // className="bg-light border-0 p-0 w-full"
                  className="bg-light border-0 p-0 w-full"
                >
                  <InputGroup className="form-group-1 border me-auto w-full flex-nowrap">
                    <FormControl
                      className="border-0 flex-grow-1"
                      style={{ minWidth: "120px" }} // Ensuring a minimum width
                      type="text"
                      name="search"
                      // value={showStaffName(item?.stylist)}
                      value={filter.filter_key.label}
                      placeholder={filter.filter_key.label}
                      autoComplete="off"
                      readOnly
                    />
                    <InputGroup.Text className="border-0  bg-transparent pe-1 ps-3">
                      <FaAngleDown />
                    </InputGroup.Text>
                  </InputGroup>
                </Dropdown.Toggle>
                <Dropdown.Menu className="">
                  {filter.filter_value.map((option) => (
                    <Dropdown.Item key={option.value}>
                      {option.value === "custom" ? (
                        <>
                          <Form.Check type="radio">
                            <Form.Check.Input
                              type="radio"
                              checked={option.isSelected}
                              onChange={(e) =>
                                handleFilterChange(
                                  filter.filter_key.value,
                                  option.value,
                                  e.target.checked
                                )
                              }
                            />
                            <Form.Check.Label className="ms-2 text-wrap">
                              {option.label}
                            </Form.Check.Label>
                          </Form.Check>
                          {/* <label>
                          <input
                            type="radio"
                            checked={option.isSelected}
                            onChange={() =>
                              handleFilterChange(
                                filter.filter_key.value,
                                "custom",
                                true
                              )
                            }
                          />
                          {option.label}
                        </label> */}
                          {option.isSelected && (
                            <div>
                              <DatePicker
                                selected={
                                  option.startDate
                                    ? new Date(option.startDate)
                                    : null
                                }
                                onChange={(date) =>
                                  handleDateRangeChange(
                                    filter.filter_key.value,
                                    date,
                                    option.endDate
                                  )
                                }
                                selectsStart
                                startDate={
                                  option.startDate
                                    ? new Date(option.startDate)
                                    : null
                                }
                                // startDate={new Date(option.startDate)}
                                endDate={
                                  option.endDate
                                    ? new Date(option.endDate)
                                    : null
                                }

                                // dateFormat={"yyyy-MM-DD"}
                              />
                              <DatePicker
                                selected={
                                  option.endDate
                                    ? new Date(option.endDate)
                                    : null
                                }
                                onChange={(date) =>
                                  handleDateRangeChange(
                                    filter.filter_key.value,
                                    option.startDate,
                                    date
                                  )
                                }
                                selectsEnd
                                startDate={
                                  option.startDate
                                    ? new Date(option.startDate)
                                    : null
                                }
                                endDate={
                                  option.endDate
                                    ? new Date(option.endDate)
                                    : null
                                }
                                minDate={
                                  option.startDate
                                    ? new Date(option.startDate)
                                    : null
                                }
                              />
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <Form.Check type="checkbox">
                            <Form.Check.Input
                              type="checkbox"
                              checked={option.isSelected}
                              onChange={(e) =>
                                handleFilterChange(
                                  filter.filter_key.value,
                                  option.value,
                                  e.target.checked
                                )
                              }
                            />
                            <Form.Check.Label className="ms-2 text-wrap">
                              {option.label}
                            </Form.Check.Label>
                          </Form.Check>
                          {/* <label>
                          <input
                            type="checkbox"
                            checked={option.isSelected}
                            onChange={(e) =>
                              handleFilterChange(
                                filter.filter_key.value,
                                option.value,
                                e.target.checked
                              )
                            }
                            className="ms-2 text-wrap"
                          />
                          {option.label}
                        </label> */}
                        </>
                      )}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ))}

          {/* {filterList.map((filter) => (
          <div key={filter.filter_key.value}>
            <h4>{filter.filter_key.label}</h4>
            {filter.filter_value.map((option) => (
              <div key={option.value}>
                {option.value === "custom" ? (
                  <div>
                    <label>
                      <input
                        type="radio"
                        checked={option.isSelected}
                        onChange={() =>
                          handleFilterChange(
                            filter.filter_key.value,
                            "custom",
                            true
                          )
                        }
                      />
                      {option.label}
                    </label>
                    {option.isSelected && (
                      <div>
                        <DatePicker
                          selected={
                            option.startDate ? new Date(option.startDate) : null
                          }
                          onChange={(date) =>
                            handleDateRangeChange(
                              filter.filter_key.value,
                              date,
                              option.endDate
                            )
                          }
                          selectsStart
                          startDate={
                            option.startDate ? new Date(option.startDate) : null
                          }
                          // startDate={new Date(option.startDate)}
                          endDate={
                            option.endDate ? new Date(option.endDate) : null
                          }

                          // dateFormat={"yyyy-MM-DD"}
                        />
                        <DatePicker
                          selected={
                            option.endDate ? new Date(option.endDate) : null
                          }
                          onChange={(date) =>
                            handleDateRangeChange(
                              filter.filter_key.value,
                              option.startDate,
                              date
                            )
                          }
                          selectsEnd
                          startDate={
                            option.startDate ? new Date(option.startDate) : null
                          }
                          endDate={
                            option.endDate ? new Date(option.endDate) : null
                          }
                          minDate={
                            option.startDate ? new Date(option.startDate) : null
                          }
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <label>
                    <input
                      type="checkbox"
                      checked={option.isSelected}
                      onChange={(e) =>
                        handleFilterChange(
                          filter.filter_key.value,
                          option.value,
                          e.target.checked
                        )
                      }
                    />
                    {option.label}
                  </label>
                )}
              </div>
            ))}
          </div>
        ))} */}

          <div className="filter-actions">
            <button className="btn btn-secondary" onClick={handleClearAll}>
              Clear All
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                dispatch(fetchRecordLists()); // Fetch records with updated filters
              }}
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    </Card>
  );
};

const InvoiceTable = ({
  loading,
  invoiceList,
  totalInvoices,
  currentPage,
  last_page,
  perPage,
  onPageChange,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [recordInventoryModelShow, setRecordInventoryModelShow] =
    useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDeleteInvoice = () => {
    // open the modal
    // setShowModal(true);
    console.log({ selectedInvoice });

    dispatch(setDeleteCheckoutId(selectedInvoice.id));

    // dispatch(fetchRecordLists());
    dispatch(fetchRecordLists()).then((action) => {
      if (action.meta.requestStatus === "fulfilled") {
        setSelectedInvoice(null);
        dispatch(clearDeleteCheckoutId());
        setShowModal(false); // Close the model
      } else {
        toast.error("Error fetching invoices");
      }
    });
  };
  const handleAction = (action, invoice) => {
    setSelectedInvoice(invoice);
    switch (action) {
      case "view":
        window.open(invoice.invoice_url, "_blank");
        break;
      case "edit":
        if (invoice.isInvoiceEdit) {
          // Logic for editing invoice
          console.log("Edit invoice:", invoice);
          navigate(`/salesNew/editInvoice/${invoice?.id}`);
        }
        break;
      case "delete":
        if (invoice.isInvoiceDelete) {
          // Logic for deleting invoice
          console.log("Delete invoice:", invoice);
          setShowModal(true);
          // handleDeleteInvoice();
        }
        break;
      case "updateInventory":
        if (invoice.isUpdateInventory) {
          // Logic for updating inventory
          console.log("Update inventory for:", invoice);
          setRecordInventoryModelShow(true);
        }
        break;
      default:
        console.log("No action selected");
    }
  };
  const renderTooltip = (text) => <Tooltip id="tooltip">{text}</Tooltip>;
  const modulesPermissions = useSelector(
    (state) => state?.authSession?.modulesPermissions
  );
  return (
    <>
      {loading && (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      )}{" "}
      {invoiceList?.length ? (
        <>
          <div className="mt-0">
            <Card className="rounded-bottom-0 rounded-top-0">
              <Card.Body className="hstack justify-content-between gap-2 flex-wrap">
                {/* <h6>{productListData?.data?.length} Products </h6> */}
                <h6>{ShowTotalEntriesCount(totalInvoices, "Sale", "Sales")}</h6>
              </Card.Body>
            </Card>
          </div>

          <div className="table-responsive border-start border-end mb-4">
            <Table striped hover>
              <thead>
                <tr>
                  <th>Client name</th>
                  <th>Date & time</th>
                  <th>Items</th>
                  <th>Client tag</th>
                  <th>Staff appointed</th>
                  <th>Amount</th>
                  <th>Payment mode</th>

                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {invoiceList?.map((invoice, index) => {
                  const items = [
                    ...invoice.services,
                    ...invoice.products,
                    ...invoice.memberships,
                    ...invoice.packages,
                  ].join(", ");

                  return (
                    <tr key={invoice.id}>
                      <td>{invoice.client_name}</td>
                      <td>{new Date(invoice.date).toLocaleDateString()}</td>
                      <td>
                        <OverlayTrigger
                          placement="top"
                          overlay={renderTooltip(items)}
                        >
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "inline-block",
                              maxWidth: "200px",
                              cursor: "pointer",
                            }}
                          >
                            {items}
                          </span>
                        </OverlayTrigger>
                      </td>
                      <td>
                        {" "}
                        <span
                          className={`badge badge-sm border py-0 px-1 text-dark   text-xs font-regular ${getClassNameForClientTag(
                            invoice?.client_tag
                          )}`}
                        >
                          {invoice?.client_tag}
                        </span>
                      </td>
                      <td>{invoice.staff.join(" + ")}</td>
                      <td>
                        {getCurrencySymbolTextforTd(invoice.amount)}
                        {invoice.amount_due && (
                          <p className="text-danger">
                            Amount due:{" "}
                            {getCurrencySymbolTextforTd(invoice?.amount_due)}
                          </p>
                        )}
                        {/* {invoice.currency_symbol}
                        {invoice.amount} */}
                      </td>
                      <td>{invoice.payment_mode.join(", ")}</td>
                      {/* <td>{index + 1}</td> */}
                      {/* <td>{invoice.client_number}</td> */}

                      <td>
                        <Dropdown
                          as={ButtonGroup}
                          className="d-inline position-static dropdown-toggle-remove"
                        >
                          <Dropdown.Toggle
                            as="span"
                            id="dropdown-custom-1"
                            className="text-secondary h6 cursor-pointer"
                            style={{
                              border: "none",
                              background: "transparent",
                            }}
                          >
                            ...
                            {/* <BsThreeDotsVertical /> */}
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            align="end"
                            className="dropdown-menu-right"
                          >
                            <Dropdown.Item
                              as="span"
                              className="d-flex align-items-center"
                              onClick={() => handleAction("view", invoice)}
                            >
                              <HiEye className="text-secondary h6 cursor-pointer me-4" />
                              View Invoice
                            </Dropdown.Item>
                            {invoice.isInvoiceEdit && (
                              <Dropdown.Item
                                as="span"
                                className="d-flex align-items-center"
                                onClick={() => handleAction("edit", invoice)}
                                disabled={
                                  !hasEditPermission(
                                    modulesPermissions?.billing?.sales?.is_edit
                                  )
                                }
                              >
                                <HiPencil className="text-secondary h6 me-4" />
                                Edit Invoice
                              </Dropdown.Item>
                            )}
                            {invoice.isInvoiceDelete && (
                              <Dropdown.Item
                                as="span"
                                className="d-flex align-items-center"
                                disabled={
                                  !hasEditPermission(
                                    modulesPermissions?.billing?.sales
                                      ?.is_delete
                                  )
                                }
                                onClick={() => handleAction("delete", invoice)}
                              >
                                <HiTrash className="text-secondary h6 me-4" />
                                Delete Invoice
                              </Dropdown.Item>
                            )}
                            {invoice.isUpdateInventory && (
                              <Dropdown.Item
                                as="span"
                                className="d-flex align-items-center"
                                disabled={
                                  !hasEditPermission(
                                    modulesPermissions?.billing?.sales
                                      ?.is_update
                                  )
                                }
                                onClick={() =>
                                  handleAction("updateInventory", invoice)
                                }
                              >
                                <MdInventory className="text-secondary h6 cursor-pointer me-4" />
                                Update Inventory
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>{" "}
          </div>
          {/* Pagination Section */}
          <div className="mb-3">
            <Pagination size="sm" variant="primary" className="mt-3">
              <Pagination.First onClick={() => onPageChange(1)} />
              <Pagination.Prev
                onClick={() => {
                  if (currentPage > 1) {
                    onPageChange(currentPage - 1);
                  }
                }}
              />
              {Array.from(
                { length: Math.ceil(totalInvoices / perPage) },
                (_, i) => i + 1
              ).map((page) => (
                <Pagination.Item
                  key={page}
                  active={page === currentPage}
                  onClick={() => onPageChange(page)}
                >
                  {page}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() => {
                  if (currentPage < last_page) {
                    onPageChange(currentPage + 1);
                  }
                }}
              />
              <Pagination.Last onClick={() => onPageChange(last_page)} />
            </Pagination>
          </div>
        </>
      ) : (
        <>
          {!loading && (
            <div className="hstack justify-content-center mt-16">
              <div className="text-center">
                {/* <Image
                width={190}
                src={EmptyBox}
                alt="EmptyBOx"
                className="mb-10"
              /> */}
                <h6 className="mb-2">You don’t have any invoices !</h6>
                {/* <p className="mb-8">
                You can add products here and keep track of
                <br /> your inventory efficiently
              </p> */}
                <Button
                  variant="secondary"
                  onClick={() => navigate("/salesNew/record")}
                >
                  <FaPlus className="me-2" />
                  create checkout
                </Button>
              </div>
            </div>
          )}
        </>
      )}
      <RecordInventoryModel
        show={recordInventoryModelShow}
        setShow={setRecordInventoryModelShow}
        checkOutId={selectedInvoice?.id}
        pdfUrl={selectedInvoice?.invoice_url}
      />
      {/* Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this invoice?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteInvoice}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TabbedInvoicePage;
