import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  FormControl,
  InputGroup,
  Offcanvas,
  Row,
  Stack,
} from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { getStaff } from "../../redux/appointment";
import {
  validateAddEnquiry,
  validateEditExpense,
} from "../../utils/validation";
import { EnquiryEdite } from "../../redux/enquiry";
import DateTime from "react-datetime";
import {
  convertTimeToUTC,
  convertUTCToUserTimeZone,
  countryCodeToNumber,
  formatDate,
  formatDateFromISO,
  formatTime,
  getCurrencySymbolIcon,
  getPhoneCountryCode,
} from "../../utils/commonFunctions";
import { editExpense } from "../../redux/expense";
import { useNavigate } from "react-router-dom";
import { editVendor } from "../../redux/vendor";
import { toast } from "react-toastify";

function EditVendor({
  showOffcanvas1,
  setShowOffcanvas1,
  selectedVendorEntry,
  refresh,
}) {
  const [formData, setFormData] = useState({
    mobile: "",
    name: "",
    email: "",
    panNumber: "",
    gstin: "",
    street_address: "",
    pincode: "",
    city: "",
    state: "",
    notes: "",
  });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.vendors);

  useEffect(() => {
    if (selectedVendorEntry?.id) {
      
      setFormData({
        name: selectedVendorEntry?.name || "",
        mobile: selectedVendorEntry?.mobile ? `${countryCodeToNumber()}${selectedVendorEntry.mobile}` : "",
        mobileWithoutDialCode: selectedVendorEntry?.mobile || "",
        email: selectedVendorEntry?.email || "",
        // date:
        //   formatDateFromISO(
        //     convertUTCToUserTimeZone(selectedVendorEntry.date)
        //   ) || moment().format("DD-MM-YYYY"),
        panNumber: selectedVendorEntry?.panNumber || "",
        gstin: selectedVendorEntry?.gstin || "",
        street_address: selectedVendorEntry?.street_address || "",
        pincode: selectedVendorEntry?.pincode || "",
        city: selectedVendorEntry?.city || "",
        state: selectedVendorEntry?.state || "",
        notes: selectedVendorEntry?.notes || "",
      });
    }
  }, [selectedVendorEntry]);

  function isObjectEmpty(data) {
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  const handleFocus = (e) => {
    const inputValue = e.target.value;
    if (inputValue) {
      e.target.select();
    }
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   if (name === "amount" || name === "qty") {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       [name]: parseFloat(value) || 0,
  //     }));
  //     setError({ ...error, [name]: null });
  //   } else {
  //     setFormData((prevData) => ({ ...prevData, [name]: value }));
  //     setError({ ...error, [name]: null });
  //   }
  // };
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({ ...prevData, [name]: value }));
  //   setErrors({ ...errors, [name]: null });
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: null });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.mobile) newErrors.mobile = "Vendor number is required";
    if (!formData.name) newErrors.name = "Vendor name is required";
    if (formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email))
      newErrors.email = "Invalid email";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const rawPhone = formData.mobileWithoutDialCode.replace(/\D/g, ""); // Remove formatting
      try {
        const response = await dispatch(
          editVendor({ id: selectedVendorEntry?.id, updatedData: { ...formData, mobile: rawPhone } })
        ).unwrap();
        if (response) {
          setFormData({
            mobile: "",
            name: "",
            email: "",
            panNumber: "",
            gstin: "",
            street_address: "",
            pincode: "",
            city: "",
            state: "",
            notes: "",
          });
          refresh();
          setShowOffcanvas1(false);
        }
      } catch (error) {
        toast.error("Error Editing vendor");
      }
    }
  };

  return (
    <div>
      <Offcanvas
        show={showOffcanvas1}
        onHide={() => setShowOffcanvas1(false)}
        placement={"end"}
        className="margin-top-72px"
        scroll={false}
      >
        <Offcanvas.Header className="py-4" closeButton>
          <div>
            <h5 className="text-md mb-2">Edit vendor</h5>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="h-full vstack">
            <Form onSubmit={handleSubmit}>
                {/* <Col md={12}>
                <Form.Group controlId="mobile">
                  <Form.Label>Vendor Number*</Form.Label>
                  <InputGroup>
                    <InputGroup.Text
                      id="basic-addon1"
                      className="px-4 text-dark bg-light"
                    >
                      {getCurrencySymbolIcon()}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="mobile"
                      value={formatPhoneNumberText(formData.mobile)}
                      onChange={handleChange}
                      isInvalid={!!errors.mobile}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.mobile}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col> */}
                <Col md={12}>
                  <Form.Group controlId="mobile">
                    <Form.Label>Vendor Number*</Form.Label>
                    <PhoneInput
                      value={formData.mobile}
                      // country="pk" // Default country (you can change this)
                      country={getPhoneCountryCode()}
                      onChange={(value, country) => {
                        const phoneWithoutCountryCode = value.slice(
                          country.dialCode.length
                        ); // Remove country code
                        setFormData({
                          ...formData,
                          mobileWithoutDialCode: phoneWithoutCountryCode,
                          mobile: value,
                        });
                      }}
                      specialLabel="Enter phone number"
                      dropdownClass="shadow-2 rounded-2"
                      placeholder="Enter phone number"
                      autocompleteSearch
                      enableSearch
                      countryCodeEditable={false}
                    />
                    {errors.mobile && (
                      <div className="invalid-feedback d-block">
                        {errors.mobile}
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="name">
                    <Form.Label>Vendor Name*</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="panNumber">
                    <Form.Label>PAN Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="panNumber"
                      value={formData.panNumber}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="gstin">
                    <Form.Label>GSTIN</Form.Label>
                    <Form.Control
                      type="text"
                      name="gstin"
                      value={formData.gstin}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="street_address">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="street_address"
                      value={formData.street_address}
                      placeholder="Street Address"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <Form.Group controlId="pincode">
                    <Form.Label>&nbsp;</Form.Label>
                    <Form.Control
                      type="text"
                      name="pincode"
                      value={formData.pincode}
                      placeholder="Pincode"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="city">
                    <Form.Label>&nbsp;</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      value={formData.city}
                      placeholder="City"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <Form.Group controlId="state">
                    <Form.Label>&nbsp;</Form.Label>
                    <Form.Control
                      type="text"
                      name="state"
                      value={formData.state}
                      placeholder="State"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="notes">
                    <Form.Label>Notes</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="notes"
                      value={formData.notes}
                      placeholder="Notes..."
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
          <div style={{marginBottom: "100px", display: "block"}}></div>
            </Form>
          </div>
          <div className="mt-auto position-absolute bottom-0 bg-white p-6 w-full start-0 overlap-20">
            <Button
              variant="secondary"
              type="submit"
              className="w-full"
              onClick={(e) => handleSubmit(e)}
              disabled={loading}
            >
              Save changes
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default EditVendor;
