import React, { useEffect, useState } from "react";
import CommonLayout from "../../component/commonLayout";
import {
  Col,
  Form,
  Row,
  Stack,
  Button,
  Dropdown,
  InputGroup,
  FormControl,
  Alert,
} from "react-bootstrap";
import { LiaPenSolid } from "react-icons/lia";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { getStaff } from "../../redux/appointment";
import { validateAddEnquiry, validateAddExpense } from "../../utils/validation";
import { EnquiryAdd } from "../../redux/enquiry";
import { clearAddEnqiury } from "../../redux/enquiry/enquirySlice";
import { useNavigate } from "react-router-dom";
import DateTime from "react-datetime";
import {
  convertTimeToUTC,
  formatDate,
  formatPhoneNumberText,
  formatTime,
  getCurrencySymbolIcon,
  getPhoneCountryCode,
} from "../../utils/commonFunctions";
import moment from "moment";
import PageLoader from "../../component/commonLayout/PageLoader";
import {
  addExpense,
  fetchAllExpenseCategories,
  fetchAllExpenses,
} from "../../redux/expense";
import {
  clearAddExpenseStatus,
  clearExpenseCategories,
} from "../../redux/expense/expenseSlice";
import PermissionGuard from "../../component/commonLayout/PermissionGuard";
import { TbCurrencyDirham } from "react-icons/tb";
import { BiRupee } from "react-icons/bi";
import PKRupeeIcon from "../../assets/icons/currency/PKRupeeIcon";
import { addVendor } from "../../redux/vendor";

const AddVendor = () => {
  const [formData, setFormData] = useState({
    mobile: "",
    name: "",
    email: "",
    panNumber: "",
    gstin: "",
    street_address: "",
    pincode: "",
    city: "",
    state: "",
    notes: "",
  });
  const [errors, setErrors] = useState({});

  const { loading } = useSelector((state) => state.vendors);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validate = () => {
    const newErrors = {};
    if (!formData.mobile) newErrors.mobile = "Vendor number is required";
    if (!formData.name) newErrors.name = "Vendor name is required";
    if (formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email))
      newErrors.email = "Invalid email";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const rawPhone = formData.mobile.replace(/\D/g, ""); // Remove formatting
      try {

        const response = await dispatch(
          addVendor({ ...formData, mobile: rawPhone })
        ).unwrap();
        if (response) {
          setFormData({
            mobile: "",
            name: "",
            email: "",
            panNumber: "",
            gstin: "",
            street_address: "",
            pincode: "",
            city: "",
            state: "",
            notes: "",
          });
          navigate("/vendor/vendorList");
        }
      } catch (error) {
        console.error("Error adding vendor:", error);
      }
    }
  };

  const modulesPermissions = useSelector(
    (state) => state?.authSession?.modulesPermissions
  );

  return (
    <PermissionGuard hasPermission={modulesPermissions?.vendor?.is_create || true}>
      <div>
        <CommonLayout>
          {loading && <PageLoader />}
          <h4 className="mb-2">Add Vendor</h4>
          <p className="mb-6">You add your vendors here</p>

          <Form onSubmit={handleSubmit}>
            <Row>
              {/* <Col md={3}>
                <Form.Group controlId="mobile">
                  <Form.Label>Vendor Number*</Form.Label>
                  <InputGroup>
                    <InputGroup.Text
                      id="basic-addon1"
                      className="px-4 text-dark bg-light"
                    >
                      {getCurrencySymbolIcon()}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="mobile"
                      value={formatPhoneNumberText(formData.mobile)}
                      onChange={handleChange}
                      isInvalid={!!errors.mobile}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.mobile}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col> */}
              <Col md={3}>
                <Form.Group controlId="mobile">
                  <Form.Label>Vendor Number*</Form.Label>
                  <PhoneInput
                    // value={formData.mobile}
                    // country="pk" // Default country (you can change this)
                    country={getPhoneCountryCode()} 
                    onChange={(value, country) => {
                      const phoneWithoutCountryCode = value.slice(
                        country.dialCode.length
                      ); // Remove country code
                      setFormData({
                        ...formData,
                        mobile: phoneWithoutCountryCode,
                      });
                    }}
                    specialLabel="Enter phone number"
                    dropdownClass="shadow-2 rounded-2"
                    placeholder="Enter phone number"
                    autocompleteSearch
                    enableSearch
                    countryCodeEditable={false}
                  />
                  {errors.mobile && (
                    <div className="invalid-feedback d-block">
                      {errors.mobile}
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="name">
                  <Form.Label>Vendor Name*</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={3}>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="panNumber">
                  <Form.Label>PAN Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="panNumber"
                    value={formData.panNumber}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Form.Group controlId="gstin">
                  <Form.Label>GSTIN</Form.Label>
                  <Form.Control
                    type="text"
                    name="gstin"
                    value={formData.gstin}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Form.Group controlId="street_address">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="street_address"
                    value={formData.street_address}
                    placeholder="Street Address"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col md={3}>
                <Form.Group controlId="pincode">
                  <Form.Label>&nbsp;</Form.Label>
                  <Form.Control
                    type="text"
                    name="pincode"
                    value={formData.pincode}
                    placeholder="Pincode"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="city">
                  <Form.Label>&nbsp;</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={formData.city}
                    placeholder="City"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col md={3}>
                <Form.Group controlId="state">
                  <Form.Label>&nbsp;</Form.Label>
                  <Form.Control
                    type="text"
                    name="state"
                    value={formData.state}
                    placeholder="State"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group controlId="notes">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="notes"
                    value={formData.notes}
                    placeholder="Notes..."
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Stack direction="horizontal" className="gap-3 mt-3">
              <Button
                variant="light"
                className="rounded-1 border px-10 ms-auto"
                onClick={() =>
                  setFormData({
                    mobile: "",
                    name: "",
                    email: "",
                    panNumber: "",
                    gstin: "",
                    street_address: "",
                    pincode: "",
                    city: "",
                    state: "",
                    notes: "",
                  })
                }
              >
                Clear all
              </Button>
              <Button type="submit" variant="secondary">
                Save
              </Button>
            </Stack>
          </Form>
        </CommonLayout>
      </div>
    </PermissionGuard>
  );
};

export default AddVendor;

const AddVendorX = () => {
  const [formData, setFormData] = useState({
    name: "",
    category: "",
    amount: "",
    date: moment().format("DD-MM-YYYY"),
    qty: 1,
    expense_type: "One-time",
    payment_mode: "Cash",
    receiver: "",
    remarks: "",
  });
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentDateTime, setCurrentDateTime] = useState("");
  const addExpenseStatus = useSelector(
    (state) => state?.expense?.addExpenseStatus
  );
  const fetchAllExpenseCategoriesStatus = useSelector(
    (state) => state?.expense?.fetchAllExpenseCategoriesStatus
  );
  const [expenseCategoriesList, setExpenseCategoriesList] = useState([]);
  const expenseCategories = useSelector(
    (state) => state?.expense?.expenseCategories
  );
  useEffect(() => {
    if (expenseCategories?.length) {
      setExpenseCategoriesList(expenseCategories);
    }
  }, [expenseCategories]);
  useEffect(() => {
    if (
      expenseCategories &&
      isObjectEmpty(expenseCategories) &&
      fetchAllExpenseCategoriesStatus === "idle"
    ) {
      dispatch(fetchAllExpenseCategories());
    }
  }, [expenseCategories, dispatch]);
  useEffect(() => {
    if (addExpenseStatus === "succeeded") {
      navigate("/expense/expenses");
      dispatch(clearAddExpenseStatus());
      dispatch(fetchAllExpenses({}));
      dispatch(clearExpenseCategories());
    }
  }, [addExpenseStatus]);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const formattedDateTime = `${formatDates(now)} | ${formatTimes(now)}`;
      setCurrentDateTime(formattedDateTime);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTimes = (date) => {
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const formatDates = (date) => {
    const options = { month: "short", day: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  function isObjectEmpty(data) {
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCategories, setFilteredCategories] = useState(
    expenseCategoriesList
  );
  useEffect(() => {
    setFilteredCategories(
      expenseCategoriesList.filter((result) =>
        result.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, expenseCategoriesList]);

  const setValueInTextBox = (result) => {
    setSearchQuery(result || "");
    handleInputChange({ target: { name: "category", value: result } });
  };
  const handleFocus = (e) => {
    const inputValue = e.target.value;
    if (inputValue) {
      e.target.select();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "amount" || name === "qty") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: parseFloat(value) || 0,
      }));
      setError({ ...error, [name]: null });
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
      setError({ ...error, [name]: null });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let fromError = validateAddExpense(formData);
    if (Object.keys(fromError).length > 0) {
      setError(fromError);
    } else {
      const dateUTC = convertTimeToUTC(
        formData.date,
        moment().format("hh:mm A")
      );

      // Handle invalid times
      if (!dateUTC) {
        console.error("One of the times is invalid");
        return; // Or handle the error accordingly
      }

      // Update the appointment object with UTC values
      const updatedFormData = {
        ...formData,
        date: dateUTC, // Date in UTC
      };

      dispatch(addExpense(updatedFormData));
      setFormData({
        name: "",
        category: "",
        amount: "",
        date: moment().format("DD-MM-YYYY"),
        qty: 1,
        expense_type: "One-time",
        payment_mode: "Cash",
        receiver: "",
        remarks: "",
      });
    }
  };

  const handleDateChange = (name, time, date) => {
    // const formattedDate = date ? formatDate(time._d) : formatTime(time._d);
    const formattedDate = date
      ? moment(time).format("DD-MM-YYYY")
      : moment(time).format("hh:mm A");

    const momentDate = moment(
      formattedDate,
      date ? "DD-MM-YYYY" : "hh:mm A",
      true
    );
    if (!momentDate.isValid()) {
      console.log("Invalid date or time");
      return;
    }
    // Update state with the formatted date
    setFormData((prevBookAppointment) => ({
      ...prevBookAppointment,
      [name]: formattedDate,
    }));
  };

  const modulesPermissions = useSelector(
    (state) => state?.authSession?.modulesPermissions
  );

  return (
    <PermissionGuard hasPermission={modulesPermissions?.expense?.is_create}>
      <div>
        <CommonLayout>
          {addExpenseStatus === "loading" && <PageLoader />}
          <h4 className="mb-2">Record Expense</h4>
          <p className="mb-6">Add your daily expenses here</p>
          {/* <Stack direction="horizontal" className="gap-3 mb-6">
          <p className="text-sm font-bold text-dark ms-auto">
            Enquiry created on:
          </p>
          <p className="text-sm font-bold text-dark">{currentDateTime}</p>
          <LiaPenSolid className="text-secondary h5" />
        </Stack> */}
          {/* <p className="font-semibold mb-4">Contact details</p> */}
          <Form className="mb-8">
            <Row>
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Name</Form.Label>

                  <Form.Control
                    type="text"
                    name="name"
                    value={formData?.name}
                    onChange={handleInputChange}
                  />
                  <p className="text-danger">{error?.name}</p>
                </Form.Group>
              </Col>
              {/* <Col sm={3}>
              <Form.Group>
                <Form.Label>Category*</Form.Label>
                <Form.Control
                  type="text"
                  name="category"
                  value={formData.category}
                  onChange={handleInputChange}
                />
                <p className="text-danger">{error?.category}</p>
              </Form.Group>
            </Col> */}
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Category*</Form.Label>
                  <Dropdown
                    className="bg-light dropdown-toggle-remove w-full"
                    autoClose="outside"
                  >
                    <Dropdown.Toggle
                      id="dropdown-autoclose-outside"
                      className="bg-light border-0 p-0 w-full"
                    >
                      <InputGroup className="form-group-1 border me-auto w-full">
                        {
                          <FormControl
                            className="border-0 "
                            type="text"
                            name="category"
                            autoComplete="off"
                            // value={searchQuery}
                            value={formData?.category}
                            // readOnly
                            onChange={(e) => {
                              setSearchQuery(e.target.value);
                              handleInputChange(e);
                            }}
                            onFocus={handleFocus}
                          />
                        }
                        {/* <InputGroup.Text className="border-0  bg-transparent pe-1 ps-3">
              <FaAngleDown />
            </InputGroup.Text> */}
                      </InputGroup>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="w-full">
                      {/* {productArray?.map((result, result_index) => ( */}
                      {filteredCategories?.map((result, result_index) => (
                        <Dropdown.Item key={result_index}>
                          {/* <Form.Check
                          type="radio"
                          id={`category_${result_index}`}
                        >
                          <Form.Check.Input
                            type="radio"
                            name={`category_${result_index}`}
                            style={{ display: "none" }} // Hide the radio input
                            checked={formData?.category === result}
                            onChange={(e) => setValueInTextBox(result)}
                          />
                          <Form.Check.Label
                            className="ms-2 text-wrap"
                            onClick={() => setValueInTextBox(result)}
                          >
                            {result}
                          </Form.Check.Label>
                        </Form.Check> */}
                          <Form.Label
                            className="text-wrap cursor-pointer w-full"
                            onClick={() => setValueInTextBox(result)}
                          >
                            {result}
                          </Form.Label>
                        </Dropdown.Item>
                      ))}
                      {filteredCategories?.length === 0 &&
                        searchQuery !== "" && (
                          <Dropdown.ItemText>
                            No categories found for given search .
                          </Dropdown.ItemText>
                        )}
                    </Dropdown.Menu>
                  </Dropdown>
                  <p className="text-danger">{error?.category}</p>
                </Form.Group>
              </Col>
              <Col sm={3}>
                {/* <Form.Group>
                  <Form.Label>Amount*</Form.Label>
                  <Form.Control
                    type="text"
                    name="amount"
                    value={formData.amount}
                    onChange={handleInputChange}
                  />

                  <p className="text-danger">{error?.amount}</p>
                </Form.Group> */}

                <Form.Group controlId="amount">
                  <Form.Label>Amount*</Form.Label>
                  <InputGroup>
                    <InputGroup.Text
                      id="basic-addon1"
                      className="px-4 text-dark bg-light"
                    >
                      {getCurrencySymbolIcon()}
                    </InputGroup.Text>
                    <Form.Control
                      // type="number"
                      type="text"
                      className="text-end border-start-0"
                      placeholder="Enter purchase price"
                      name="amount"
                      value={formData.amount}
                      onChange={handleInputChange}
                      // onWheel={(e) => e.target.blur()}
                    />
                  </InputGroup>
                  <span className="text-danger">{error?.amount}</span>
                </Form.Group>
              </Col>

              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Date*</Form.Label>
                  <DateTime
                    value={formData?.date}
                    input={true} // Enable the input field
                    dateFormat="DD-MM-yyyy" // Date format
                    timeFormat={false} // Disable the time picker
                    onChange={(time) => handleDateChange("date", time, true)}
                    inputProps={{ placeholder: "DD-MM-YYYY" }}
                  />
                  {/* <Form.Control
                  type="date"
                  name="date_to_followup"
                  date_to_followup
                  value={formData?.date_to_followup}
                  onChange={handleInputChange}
                /> */}
                  <p className="text-danger">{error?.date}</p>
                </Form.Group>
              </Col>
            </Row>
          </Form>
          {/* <p className="font-semibold mb-4">Enquiry details</p> */}
          <Form className="mb-14">
            <Row className="gy-8">
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Qty</Form.Label>
                  <Form.Control
                    type="text"
                    name="qty"
                    value={formData.qty}
                    onChange={handleInputChange}
                  />
                  <p className="text-danger">{error?.qty}</p>
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Expense type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={handleInputChange}
                    value={formData.expense_type}
                    name="expense_type"
                    className="text-gray"
                  >
                    {/* <option value="">select Type</option> */}
                    <option value="One-time">One Time</option>
                    <option value="Recurring">Recurring</option>
                  </Form.Select>
                  <p className="text-danger">{error?.expense_type}</p>
                </Form.Group>
              </Col>

              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Payment mode</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    className="text-gray"
                    value={formData?.payment_mode}
                    name="payment_mode"
                    onChange={handleInputChange}
                  >
                    {/* <option value="">select Mode of payment</option> */}
                    <option value="Cash">Cash</option>
                    <option value="Online">Online</option>
                  </Form.Select>
                  <p className="text-danger">{error?.payment_mode}</p>
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Receiver</Form.Label>

                  <Form.Control
                    type="text"
                    name="receiver"
                    value={formData?.receiver}
                    onChange={handleInputChange}
                  />
                  <p className="text-danger">{error?.receiver}</p>
                </Form.Group>
              </Col>

              <Col sm={12}>
                <Form.Group>
                  <Form.Label>Remarks</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData?.remarks}
                    name="remarks"
                    onChange={handleInputChange}
                  />
                  <p className="text-danger">{error?.remarks}</p>
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <Stack direction="horizontal" className="gap-3">
            <Button
              variant="light"
              className="rounded-1 border px-10 ms-auto"
              onClick={() =>
                setFormData({
                  name: "",
                  category: "",
                  amount: "",
                  date: moment().format("DD-MM-YYYY"),
                  qty: 1,
                  expense_type: "One-time",
                  payment_mode: "Cash",
                  receiver: "",
                  remarks: "",
                })
              }
            >
              Clear all
            </Button>
            <Button variant="secondary" onClick={handleSubmit}>
              Save
            </Button>
          </Stack>
        </CommonLayout>
      </div>
    </PermissionGuard>
  );
};
