import { createSlice } from "@reduxjs/toolkit";
import {
  addProduct,
  deleteProduct,
  editProduct,
  fetchCatagories,
  fetchMasterProduct,
  fetchProductList,
} from ".";

const inventorySlice = createSlice({
  name: "inventory",
  initialState: {
    retail: {
      filters: { search: "", category: "", page: 1 },
      products: [],
      pagination: { total: 0, last_page: 1 },
    },
    professional: {
      filters: { search: "", category: "", page: 1 },
      products: [],
      pagination: { total: 0, last_page: 1 },
    },
    loading: false,
    categories: [],
    productListFromBarcodeScan: [],
  },
  reducers: {
    inventoryLoadingStart: (state) => {
      state.loading = true;
    },
    inventoryLoadingEnd: (state) => {
      state.loading = false;
    },
    // setFilter: (state, action) => {
    //   const { tab, key, value } = action.payload;
    //   state[tab]["filters"][key] = value; // Update filter (search/category/page) for a specific tab
    // },
    setFilter: (state, action) => {
      const { tab, filters } = action.payload;
      state[tab].filters = { ...state[tab].filters, ...filters };
    },
    removeProduct(state, action) {
      const { tab, productId } = action.payload;
      state[tab].products = state[tab].products.filter(
        (product) => product.id !== productId
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProductList.fulfilled, (state, action) => {
        const { tab, data } = action.payload;
        state[tab].products = data?.data || [];
        state[tab].pagination = data?.pagination;
        state[tab].totalPages = data?.pagination?.last_page || 0;
      })
      .addCase(fetchProductList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        const productId = action.payload;
        // Remove product from both retail and professional lists if applicable
        state.retail.products = state.retail.products.filter(
          (product) => product.id !== productId
        );
        state.professional.products = state.professional.products.filter(
          (product) => product.id !== productId
        );
      })
      .addCase(editProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(editProduct.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(editProduct.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchCatagories.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCatagories.fulfilled, (state, action) => {
        state.categories = action.payload;
        state.loading = false;
      })
      .addCase(fetchCatagories.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addProduct.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchMasterProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMasterProduct.fulfilled, (state, action) => {
        state.productListFromBarcodeScan = action.payload;
        state.loading = false;
      })
      .addCase(fetchMasterProduct.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { inventoryLoadingStart, inventoryLoadingEnd, setFilter } =
  inventorySlice.actions;
export default inventorySlice.reducer;
