import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { MdError } from "react-icons/md";
import { deleteVendor } from "../../redux/vendor";
import { toast } from "react-toastify";

function DeleteVendorModel({
  deleteModelShow,
  setDeleteModelShow,
  selectedVendorEntry,
  refresh,
}) {
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.vendors);

  const handleDeleteExpenseClick = (id) => {
    // dispatch(deleteVendor(id));
    dispatch(deleteVendor(id)).then((action) => {
      if (action.meta.requestStatus === "fulfilled") {
        refresh();
        setDeleteModelShow(false);
      } else {
       toast.error("Error Deleting Vendor");
      }
    });
  };
  return (
    <div>
      <Modal
        centered
        size="lg"
        show={deleteModelShow}
        onHide={() => setDeleteModelShow(false)}
      >
        <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <MdError className="text-48 text-secondary mb-4" />
          <h4 className="mb-4">Are you sure you want to delete this Vendor?</h4>
          <p className="text-sm mb-8">
            Once deleted the entry can not be retained.
          </p>
          <div className="hstack gap-2 justify-content-center">
            <Button
              size="sm"
              className="px-8"
              variant="outline-dark"
              disabled={loading}
              onClick={() => {
                handleDeleteExpenseClick(selectedVendorEntry?.id);
              }}
            >
              Yes
            </Button>
            <Button
              size="sm"
              className="px-8"
              variant="secondary"
              onClick={() => setDeleteModelShow(false)}
            >
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DeleteVendorModel;
