// Redux slice for managing tabbed invoice listing
import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../apis/api";
import { loadingEnd, loadingStart } from "../setLoading/setLoading";
import { fetchRecordLists } from "../salesNewListing";
import ToastMessage from "../../component/toast/ToastMessage";

export const fetchCheckoutItemLists = createAsyncThunk(
  "checkout/fetchCheckoutItemLists",
  async (params, { getState, rejectWithValue }) => {
    try {
      const backend = new API();
      const response = await backend.fetch(API.getPosLists(), {
        method: "GET",
      });

      // return response.data;
      return response;
    } catch (error) {
      console.log(error);

      return rejectWithValue(error.response.data);
    }
  }
);

export const addCheckout = createAsyncThunk(
  "checkout/addCheckout",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, JSON.stringify(data[key]));
      }
      // let rawData = JSON.stringify(data);
      // // rawData= JSON.stringify(rawData);
      // console.log(rawData);

      const backend = new API();
      const response = await backend.fetch(API.processWebCheckout(), {
        method: "POST",
        body: formData,
        // body: rawData,
      });
      dispatch(loadingEnd());
      if (response.status === true) {
        dispatch(fetchRecordLists());
      }
      ToastMessage(response);
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Adding Record Sale:", error);
      return rejectWithValue(error);
    }
  }
);

export const fetchCheckoutDetails = createAsyncThunk(
  "checkout/fetchCheckoutDetails",
  async (id, { getState, rejectWithValue }) => {
    try {
      const backend = new API();
      const response = await backend.fetch(API.getProcessWebCheckout(id), {
        method: "GET",
      });

      // return response.data;
      return response;
    } catch (error) {
      console.log(error);

      return rejectWithValue(error.response.data);
    }
  }
);

export const editCheckout = createAsyncThunk(
  "checkout/editCheckout",
  async ({ id, data }, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, JSON.stringify(data[key]));
      }

      const backend = new API();
      const response = await backend.fetch(API.editProcessWebCheckout(id), {
        method: "POST",
        body: formData,
        // body: rawData,
      });
      dispatch(loadingEnd());
      if (response.status === true) {
        dispatch(fetchRecordLists());
      }
      ToastMessage(response);
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Editing checkout:", error);
      return rejectWithValue(error);
    }
  }
);
